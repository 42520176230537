export const listParticipantConnections = /* GraphQL */ `
  query ListParticipantConnections(
    $username: String
    $id: ModelIDKeyConditionInput
    $filter: ModelParticipantConnectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipantConnections(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accessToken
        authorizationCode
        externalVehicleId
        refreshToken
        resourceProvider
        id
        username
        vehicleId
        createdAt
        updatedAt
        forceRestoreConnection
      }
      nextToken
    }
  }
`;

export const getTransactionsByUsernameByCreatedAt = /* GraphQL */ `
  query GetTransactionsByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        description
        type
        status
        retries
        subtotalCents
        discountCents
        amountCents
        paymentMethodId
        paidAt
        statementId
        tripSegments {
          items {
            id
            mileage
            fuel
            mileageFeeCents
            fuelFeeCents
            cordonFeeCents
            todFeeCents
            tollFeeCents
            paymentStatus
          }
        }
      }
      nextToken
    }
  }
`;
