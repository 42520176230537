import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import EnrollmentReport from './EnrollmentReport';
import CustomerInteractionReport from './CustomerInteractionReport';
import CustomerInteractionMetricsReport from './CustomerInteractionMetricsReport';
import BillingTripReport from './BillingTripReport';
import BillingMonthReport from './BillingMonthReport';
import BillingPTDReport from './BillingPTDReport';
import ErrorsEventsReport from './ErrorsEventsReport';
import PilotCloseoutReport from './PilotCloseoutReport';
import SummaryReport from './SummaryReport';
import BillingTripStatementReport from './BillingTripStatementReport';
import PaidTransactionsReport from './PaidTransactionsReport';
import RefundTransactionsReport from './RefundTransactionsReport';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export default function Report() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <EnrollmentReport />
      <CustomerInteractionReport />
      <CustomerInteractionMetricsReport />
      <BillingTripReport />
      <BillingMonthReport />
      <BillingPTDReport />
      <BillingTripStatementReport />
      <ErrorsEventsReport />
      <PilotCloseoutReport />
      <SummaryReport />
      <PaidTransactionsReport />
      <RefundTransactionsReport />
    </div>
  );
}
