import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';
import { convertMileage } from 'utilities/format';

import {
  getEventsByUserByTimestamp,
  listMROEventsWithParticipants,
} from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function getParticipantStatusReason(diff) {
  const flags = diff.find(({ key }) => key === 'flags');
  let reason = '';

  if (flags) {
    if (/"isBillingOverdue":true/.test(flags.new)) {
      reason += 'Unpaid invoice. ';
    }
    if (/"isInactive":true/.test(flags.new)) {
      reason += 'Missing device data. ';
    }
    if (/"isVinMismatch":true/.test(flags.new)) {
      reason += 'Vin mismatch. ';
    }
  }
  return reason;
}

function getCode(desc) {
  if (desc === 'OBDII device has not reported data for a 72-hour period') {
    return 1001;
  } else if (/^Failed to match participant vehicle vin/.test(desc)) {
    return 1002;
  } else if (desc === 'Can not find match trips for the toll transaction.') {
    return 1003;
  } else if (desc === 'Trip processed.') {
    return 1004;
  } else if (/^Failed to process trip/.test(desc)) {
    return 1005;
  } else if (/^Calculated fuel/.test(desc)) {
    return 1006;
  } else if (/^Failed to decode the vehicle VIN/.test(desc)) {
    return 1007;
  } else if (/^Failed to match participant vehicle to the vin decoded vehicle/.test(desc)) {
    return 1008;
  } else if (/^odoMro/.test(desc)) {
    return 1009;
  } else if (/^Device Health/.test(desc)) {
    return 1;
  } else if (/^Vin Change/.test(desc)) {
    return 2;
  } else if (/^Device Connected/.test(desc)) {
    return 3;
  } else if (/^Device Disconnected/.test(desc)) {
    return 4;
  } else if (/^Device Heartbeat/.test(desc)) {
    return 5;
  } else if (/^Vehicle Battery/.test(desc)) {
    return 12;
  } else if (/^Vehicle MIL/.test(desc)) {
    return 15;
  } else if (/^Trip [^\s]* is incomplete/.test(desc)) {
    return 16;
  } else if (/^Trip [^\s]* has no mileage/.test(desc)) {
    return 17;
  } else if (/^Trip [^\s]* could not be posted to rating/.test(desc)) {
    return 98;
  } else if (/Device [^\s]* reported excessive mileage/.test(desc)) {
    return 99;
  } else {
    return '';
  }
}

function mapFields(mroEvent, participant, vehicle, participantEvents) {
  const id = vehicle == undefined ? `${participant.accountNo}-NoVehicle` : `${participant.accountNo}-${vehicle.vin}`;
  let mroCertId = '';
  switch (participant.mroDevicePreference) {
    case 'automatedWithLocation':
      mroCertId = '5';
      break;
    case 'automatedWithoutLocation':
      mroCertId = '4';
      break;
  }

  let vinDeleted = '';
  if (vehicle) {
    vinDeleted = Object.hasOwn(participantEvents.deleted, vehicle.id) ? participantEvents.deleted[vehicle.id].createdAt : '';
  }

  return {
    'RecordID': id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'RUC Group': participant.pilotProgram.shortName === 'MBUF+DR' ? 'Variable Rate' : 'Flat Rate',
    'VIP': participant.flags.isVIP || false,
    'VIN': vehicle == undefined ? '' : vehicle.vin,
    'MRO ID': vehicle == undefined ? '' : vehicle.mro ? vehicle.mro.deviceSerialNumber : '',
    'MRO Type': vehicle == undefined ? '' : vehicle.mro ? vehicle.mro.mroDevicePreference : '',
    'MRO Cert ID': mroCertId,
    'Error-Event ID': mroEvent.id,
    'Error-Event Date': mroEvent.createdAt,
    'Error-Event Code': getCode(mroEvent.description),
    'Error-Event Description': mroEvent.description,
    'Error-Event Total Miles on Date': convertMileage(mroEvent.mileage, 'mi'),
    'VIN Added Date': vehicle == undefined ? '' : vehicle.createdAt,
    'VIN Delete Date': vinDeleted,
    'VIN Delete Reason': vinDeleted === '' ? '' : 'Removed by CSR',
    'Account Flagged Date': participantEvents.flagged.map(({ timestamp }) => timestamp).join(', '),
    'Account Flagged Reason': participantEvents.flagged.map(({ reason }) => reason).join(', '),
    'Account Suspended Date': participantEvents.suspended.map(({ timestamp }) => timestamp).join(', '),
    'Account Suspended Reason': participantEvents.flagged.map(({ reason }) => reason).join(', '),
    'Account Close Date': participant.closedDate == undefined ? '' : participant.closedDate,
    'Account Close Reason': participant.closedReason == undefined ? '' : participant.closedReason,
  };
}

export default function ErrorsEventsReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      const eventTo = moment.utc().format();
      const mroEvents = await asyncListAll(listMROEventsWithParticipants, { filter: { dateTime: { between: [from, to] } } });
      const data = await Promise.all(mroEvents.map(async (mroEvent) => {
        const participant = mroEvent.participant;
        const vehicle = participant.vehicles.items.find(({ id }) => id === mroEvent.vehicleId);

        const events = await asyncListAll(getEventsByUserByTimestamp, { username: participant.username, timestamp:
          { between: [participant.createdAt, eventTo] }, filter: { or: [{ eventName: { eq: 'MODIFY' } }, { eventName: { eq: 'REMOVE' } }] } });

        const filteredParticipantEvents = events.filter(({ key, eventName }) => key.match(/^Participant/) && eventName === 'MODIFY');
        const flagged = filteredParticipantEvents.filter(({ diff }) => {
          return diff.find(({ key, new: newValue }) => key === 'status' && newValue === '"flagged"');
        }).map(({ timestamp, diff }) => {
          return { timestamp: timestamp, reason: getParticipantStatusReason(diff) };
        });
        const suspended = filteredParticipantEvents.filter(({ diff }) => {
          return diff.find(({ key, new: newValue }) => key === 'status' && newValue === '"suspended"');
        }).map(({ timestamp, diff }) => {
          return { timestamp: timestamp, reason: getParticipantStatusReason(diff) };
        });

        const vehicleEvents = {};
        const filteredVehicleEvents = events.filter(({ key, eventName }) => key.match(/^Vehicle/) && eventName === 'REMOVE');
        participant.vehicles.items.forEach((vehicle) => {
          const vehicleEvent = filteredVehicleEvents.find(({ diff }) => {
            return diff.find(({ key, old }) => key === 'id' && old === `"${vehicle.id}"`);
          });

          if (vehicleEvent) {
            vehicleEvents[vehicle.id] = vehicleEvent;
          }
        });

        const participantEvents = {
          flagged: flagged,
          suspended: suspended,
          deleted: vehicleEvents,
        };

        return mapFields(mroEvent, participant, vehicle, participantEvents);
      }));
      return data;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Errors and Events Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Errors_And_Events_Report.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
