import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ControlledInput from 'components/Form/ControlledInput';

import { updateParticipantInquiry } from 'graphql/mutations';
import { asyncGet, asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { inquiryStatuses } from 'utilities/constants/inquiryStatuses';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  form: {
    marginTop: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  scrollableDetails: {
    height: 'calc(100vh - 220px)',
    overflow: 'auto',
  },
}));

const UNKNOWN = 'N/A';

export default function InquiryOverview({ inquiry }) {
  const classes = useStyles();

  // form state
  const {
    control,
    errors,
    formState,
    handleSubmit,
  } = useForm();

  const [participantInputs, setParticipantInputs] = useState([]);
  const [vehicleInputs, setVehicleInputs] = useState([]);
  const [mroInputs, setMroInputs] = useState([]);

  const { isSubmitting } = formState;
  const inquiryInputs = [{
    type: 'text',
    name: 'id',
    label: 'Inquiry ID',
    inputProps: {
      readOnly: true,
    },
    defaultValue: inquiry.id,
    size: 'small',
  }, {
    type: 'text',
    name: 'category',
    label: 'Category',
    inputProps: {
      readOnly: true,
    },
    defaultValue: inquiry.category,
    size: 'small',
  }, {
    type: 'text',
    name: 'createdBy',
    label: 'Created By',
    inputProps: {
      readOnly: true,
    },
    defaultValue: inquiry.createdBy,
    size: 'small',
  }, {
    type: 'select',
    name: 'status',
    label: 'Status',
    defaultValue: inquiry.status,
    options: Object.keys(inquiryStatuses).map((key) => {
      return {
        label: inquiryStatuses[key],
        value: key,
      };
    }),
    required: true,
    invalidText: 'Status is required',
    size: 'small',
  }, {
    type: 'text',
    name: 'assignee',
    label: 'Assignee',
    inputProps: {
      readOnly: true,
    },
    defaultValue: inquiry.assignee || 'unassigned',
    size: 'small',
  }, {
    type: 'text',
    name: 'subject',
    label: 'Subject',
    inputProps: {
      readOnly: true,
    },
    defaultValue: inquiry.subject || 'N/A',
    size: 'small',
  }, {
    type: 'text',
    name: 'body',
    label: 'Description',
    inputProps: {
      readOnly: true,
    },
    defaultValue: inquiry.body,
    size: 'small',
  }, {
    type: 'text',
    name: 'createdAt',
    label: 'Created At',
    inputProps: {
      readOnly: true,
    },
    defaultValue: inquiry.createdAt,
    size: 'small',
  }, {
    type: 'text',
    name: 'updatedAt',
    label: 'Last Updated At',
    inputProps: {
      readOnly: true,
    },
    defaultValue: inquiry.updatedAt,
    size: 'small',
  }];

  async function handleInquirySubmit({
    status,
  }) {
    try {
      const username = localStorage.getItem('ruc:username');
      await asyncRetryMutation(updateParticipantInquiry, {
        input: {
          id: inquiry.id,
          status,
          updatedBy: username,
        },
      });
    } catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    if (!inquiry) return;
    (async () => {
      const {
        username,
        name,
        email,
      } = inquiry;

      if (username) {
        const { data: { getParticipant: participantData } } = (await asyncGet( /* GraphQL */ `
          query GetParticipant($username: String!) {
            getParticipant(username: $username) {
              email
              firstName
              flags {
                isGovernmentEmployee
                isCaliforniaElected
                agreeGlobalParticipantAgreement
                agreeGlobalPrivacyPolicy
              }
              lastName
              governmentAffiliation
              mroDataProviderPreference
              mroDevicePreference
              onboardedDate
              pilotProgram {
                name
              }
              phoneNumber
              preferredContactType
              status
              username
            }
          }
        `, { username: inquiry.username }));

        const participant = participantData || {
          username,
          firstName: name.split(' ')[0],
          lastName: name.split(' ')[1],
          email,
          status: UNKNOWN,
          phoneNumber: UNKNOWN,
          preferredContactType: 'email',
          flags: {
            isGovernmentEmployee: false,
            isCaliforniaElected: false,
            agreeGlobalParticipantAgreement: false,
            agreeGlobalPrivacyPolicy: false,
          },
          pilotProgram: {
            name: UNKNOWN,
          },
          mroDevicePreference: UNKNOWN,
          onboardedDate: UNKNOWN,
        };

        participant.flags = participant.flags || {
          isGovernmentEmployee: false,
          isCaliforniaElected: false,
          agreeGlobalParticipantAgreement: false,
          agreeGlobalPrivacyPolicy: false,
        };

        const participantInputs = [{
          type: 'text',
          name: 'participantUsername',
          label: 'Username',
          inputProps: {
            readOnly: true,
          },
          defaultValue: participant.username,
          size: 'small',
        }, {
          type: 'text',
          name: 'participantStatus',
          label: 'Status',
          inputProps: {
            readOnly: true,
          },
          defaultValue: participant.status,
          size: 'small',
        }, {
          type: 'text',
          name: 'participantFullname',
          label: 'Participant Name',
          inputProps: {
            readOnly: true,
          },
          defaultValue: `${participant.firstName} ${participant.lastName}`,
          size: 'small',
        }, {
          type: 'text',
          name: 'participantEmail',
          label: 'Email',
          inputProps: {
            readOnly: true,
          },
          defaultValue: participant.email,
          size: 'small',
        }, {
          type: 'text',
          name: 'participantPhoneNumber',
          label: 'Phone Number',
          inputProps: {
            readOnly: true,
          },
          defaultValue: participant.phoneNumber,
          size: 'small',
        }, {
          type: 'text',
          name: 'participantPreferredContactType',
          label: 'Preferred Contact Type',
          inputProps: {
            readOnly: true,
          },
          defaultValue: participant.preferredContactType,
          size: 'small',
        }, {
          type: 'text',
          name: 'isGovernmentEmployee',
          label: 'Government Employee',
          inputProps: {
            readOnly: true,
          },
          defaultValue: (participant.flags.isGovernmentEmployee) ? 'yes' : 'no',
          size: 'small',
        }, {
          type: 'text',
          name: 'isCaliforniaElected',
          label: 'Calfironia Electred Representative',
          inputProps: {
            readOnly: true,
          },
          defaultValue: (participant.flags.isCaliforniaElected) ? 'yes' : 'no',
          size: 'small',
        }, {
          type: 'text',
          name: 'participantPilot',
          label: 'Program',
          inputProps: {
            readOnly: true,
          },
          defaultValue: participant.pilotProgram.name,
          size: 'small',
        }, {
          type: 'text',
          name: 'participantMroDevicePreference',
          label: 'MRO Device Preference',
          inputProps: {
            readOnly: true,
          },
          defaultValue: participant.mroDevicePreference,
          size: 'small',
        }, {
          type: 'text',
          name: 'participantMroDataProviderPreference',
          label: 'Data Provider Preference',
          inputProps: {
            readOnly: true,
          },
          defaultValue: participant.mroDataProviderPreference || 'N/A',
          size: 'small',
        }, {
          type: 'text',
          name: 'participantOnboardedDate',
          label: 'Onboarded Date',
          inputProps: {
            readOnly: true,
          },
          defaultValue: participant.onboardedDate,
          size: 'small',
        }, {
          type: 'text',
          name: 'participantGovernmentAffiliation',
          label: 'Government Affiliation',
          inputProps: {
            readOnly: true,
          },
          defaultValue: participant.governmentAffiliation,
          size: 'small',
        }];
        setParticipantInputs(participantInputs);

        // Existing participants
        if (participantData) {
          const vehicles = await asyncListAll( /* GraphQL */ `
            query ListVehicles(
              $username: String
              $id: ModelIDKeyConditionInput
              $filter: ModelVehicleFilterInput
              $limit: Int
              $nextToken: String
              $sortDirection: ModelSortDirection
            ) {
              listVehicles(
                username: $username
                id: $id
                filter: $filter
                limit: $limit
                nextToken: $nextToken
                sortDirection: $sortDirection
              ) {
                items {
                  id
                  username
                  vin
                  licensePlate
                  make
                  model
                  year
                  registrationState
                  beginningOdometerReading
                  currentOdometerReading
                  mileage
                }
                nextToken
              }
            }
          `, {
            username,
            filter: {
              isPrimary: {
                eq: true,
              },
            },
          }, { bypassCache: false });

          const vehicle = vehicles[0];
          if (!vehicle) return;

          const vehicleInputs = [{
            type: 'text',
            name: 'vin',
            label: 'Vehicle VIN',
            inputProps: {
              readOnly: true,
            },
            defaultValue: vehicle.vin,
            size: 'small',
          }, {
            type: 'text',
            name: 'licensePlate',
            label: 'License Plate',
            inputProps: {
              readOnly: true,
            },
            defaultValue: vehicle.licensePlate,
            size: 'small',
          }, {
            type: 'text',
            name: 'make',
            label: 'Make',
            inputProps: {
              readOnly: true,
            },
            defaultValue: vehicle.make,
            size: 'small',
          }, {
            type: 'text',
            name: 'model',
            label: 'Model',
            inputProps: {
              readOnly: true,
            },
            defaultValue: vehicle.model,
            size: 'small',
          }, {
            type: 'text',
            name: 'year',
            label: 'Year',
            inputProps: {
              readOnly: true,
            },
            defaultValue: vehicle.year,
            size: 'small',
          }, {
            type: 'text',
            name: 'registrationState',
            label: 'Registration State',
            inputProps: {
              readOnly: true,
            },
            defaultValue: vehicle.registrationState,
            size: 'small',
          }, {
            type: 'text',
            name: 'beginningOdometerReading',
            label: 'Begin Odometer',
            inputProps: {
              readOnly: true,
            },
            defaultValue: vehicle.beginningOdometerReading,
            size: 'small',
          }, {
            type: 'text',
            name: 'currentOdometerReading',
            label: 'Current Odometer',
            inputProps: {
              readOnly: true,
            },
            defaultValue: vehicle.currentOdometerReading,
            size: 'small',
          }, {
            type: 'text',
            name: 'mileage',
            label: 'Mileage',
            inputProps: {
              readOnly: true,
            },
            defaultValue: vehicle.mileage,
            size: 'small',
          }];

          setVehicleInputs(vehicleInputs);

          const { mro = null } = vehicle;
          let mroInputs = [];
          if (mro) {
            mroInputs = [{
              type: 'text',
              name: 'deviceSerialNumber',
              label: 'Serial Number',
              inputProps: {
                readOnly: true,
              },
              defaultValue: mro.deviceSerialNumber,
              size: 'small',
            }, {
              type: 'text',
              name: 'deviceImei',
              label: 'IMEI',
              inputProps: {
                readOnly: true,
              },
              defaultValue: mro.deviceImei,
              size: 'small',
            }, {
              type: 'text',
              name: 'manufacturer',
              label: 'Manufacturer',
              inputProps: {
                readOnly: true,
              },
              defaultValue: mro.manufacturer,
              size: 'small',
            }, {
              type: 'text',
              name: 'gpsEnabled',
              label: 'GPS Enabled',
              inputProps: {
                readOnly: true,
              },
              defaultValue: mro.gpsEnabled,
              size: 'small',
            }];
          }

          setMroInputs(mroInputs);
        }
      }
    })();
  }, [inquiry]);

  return (
    <div className={classes.scrollableDetails}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6">Inquiry Details</Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleInquirySubmit)}
            noValidate
          >
            <Grid container spacing={2}>
              {inquiryInputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Update
            </Button>
          </form>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6">Participant Details</Typography>
          <form
            className={classes.form}
            noValidate
          >
            <Grid container spacing={2}>
              {participantInputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6">Vehicle Details</Typography>
          <form
            className={classes.form}
            noValidate
          >
            <Grid container spacing={2}>
              {vehicleInputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </form>
        </CardContent>
      </Card>
      {mroInputs.length > 0 && (
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h6">MRO Details</Typography>
            <form
              className={classes.form}
              noValidate
            >
              <Grid container spacing={2}>
                {mroInputs.map((input, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <ControlledInput
                        control={control}
                        errors={errors}
                        {...input}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

InquiryOverview.propTypes = {
  inquiry: PropTypes.object,
};
