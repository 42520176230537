import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';
import {
  simpleDate,
  convertMileage,
} from 'utilities/format';
import {
  listParticipantsWithVehicles,
  getEventsByUserByTimestamp,
} from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function mapFields(participant, vehicle, approvedEvent) {
  const id = `${participant.accountNo}-${vehicle.vin}`;
  const odo = vehicle.reports == undefined ? [] : vehicle.reports.sort((a, b) => {
    return moment(a.tsReportDate).isBefore(b.tsReportDate) ? -1 : 1;
  });
  const odoStart = odo.length > 0 ? convertMileage(vehicle.reports[0].odometer, 'mi') : '';
  const odoStartDate = odo.length > 0 ? simpleDate(vehicle.reports[0].tsReportDate) : '';

  // MRO Type values should be:
  //  - Plug-In Device (PID) with GPS
  //  - Plug-In Device (PID) without GPS
  //  - In-Vehicle Telematics
  //  - Manual Odometer Reporting
  let mroType = 'Unknown';
  switch (vehicle.mroType) {
    case 'manual':
      mroType = 'Manual Odometer Reporting';
      break;
    case 'automatedWithoutLocation':
      mroType = 'Plug-In Device (PID) without GPS';
      break;
    case 'automatedWithLocation':
      mroType = 'Plug-In Device (PID) with GPS';
      break;
    case 'telematics':
      mroType = 'In-Vehicle Telematics';
      break;
  }

  // Vehicle fuel type values:
  //  - Gasoline
  //  - Diesel
  //  - Electric
  //  - Other
  //  - Unknown
  let vehicleFuelType = 'Unknown';
  switch (vehicle.type) {
    case 'gas':
      vehicleFuelType = 'Gasoline';
      break;
    case 'hybrid':
      // hybrid is considered gasoline for fuel type
      vehicleFuelType = 'Gasoline';
      break;
    case 'diesel':
      vehicleFuelType = 'Diesel';
      break;
    case 'electric':
      vehicleFuelType = 'Electric';
      break;
  }

  let employer = '';
  if (participant?.flags?.isGovernmentEmployee) {
    employer = 'Government';
  } else if (participant?.flags?.isCaliforniaElected || participant?.flags?.isLegislator) {
    employer = 'Elected Official';
  }

  return {
    'Record ID': id,
    'Account ID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'Address Line 1': participant.address.address1,
    'Address Line 2': participant.address.address2,
    'City': participant.address.city,
    'State': participant.address.state,
    'Zip Code': participant.address.postalCode + (participant.address.extendedPostalCode || ''),
    'Email Address': participant.email,
    'Telephone Number': participant.phoneNumber,
    'Preferred Contact Method': participant.preferredContactType,
    'Employer': employer,
    'RUC Group': participant.pilotProgram == undefined ? '' : participant.pilotProgram.shortName === 'MBUF+DR' ? 'Variable Rate' : 'Flat Rate',
    'VIP': participant.flags == undefined ? false : participant.flags.isVIP || false,
    'VIN': vehicle.vin,
    'Vehicle Make': vehicle.make,
    'Vehicle Model': vehicle.model,
    'Vehicle Year': vehicle.year,
    'Vehicle Registration State': vehicle.registrationState,
    'Vehicle Registration Plate': vehicle.licensePlate,
    'Vehicle Fuel Type': vehicleFuelType,
    'Vehicle Fuel Economy': vehicle.epaVehicleCombinedMpg,
    'Starting Odometer': odoStart,
    'Starting Odometer Date': odoStartDate,
    'CA SB339 T&C Signed Date': participant.createdAt == undefined ? '' : simpleDate(participant.createdAt),
    'Transurban T&C Signed Date': participant.onboardedDate == undefined ? '' : simpleDate(participant.onboardedDate),
    'Account Created Date': participant.createdAt == undefined ? '' : simpleDate(participant.createdAt),
    'Account Onboarded Date': participant.onboardedDate == undefined ? '' : simpleDate(participant.onboardedDate),
    'Account Approved Date': approvedEvent == undefined ? '' : simpleDate(approvedEvent.createdAt),
    'Account Active Date': participant.firstDataReceivedDate == undefined ? '' : simpleDate(participant.firstDataReceivedDate),
    'VIN Added Date': participant.createdAt == undefined ? '' : simpleDate(participant.createdAt),
    'MRO ID': vehicle.mro ? vehicle.mro.deviceSerialNumber : '',
    'MRO Type': mroType,
    'MRO (PID) Ship Date': vehicle.mro && vehicle.mro.shippedDate != undefined ? simpleDate(vehicle.mro.shippedDate) : '',
    'MRO (PID) Shipping Address Line 1': vehicle.mro ? participant.address.address1 : '',
    'MRO (PID) Shipping Address Line 2': vehicle.mro ? participant.address.address2 : '',
    'MRO (PID) Shipping City': vehicle.mro ? participant.address.city : '',
    'MRO (PID) Shipping State': vehicle.mro ? participant.address.state : '',
    'MRO (PID) Shipping Zip Code': vehicle.mro ? participant.address.postalCode + (participant.address.extendedPostalCode || '') : '',
    'MRO (PID) Delivered Date': vehicle.mro ? vehicle.mro.deliveredDate : '',
    'MRO Trips Reported Date': participant.firstDataReceivedDate == undefined ? '' : simpleDate(participant.firstDataReceivedDate),
  };
}

export default function EnrollmentReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);

      // The report should capture participants in ANY status EXCEPT `created` and `closed`.
      const participants = await asyncListAll(listParticipantsWithVehicles, {
        filter: {
          onboardedDate: { between: [from, to] },
          or: [
            { status: { eq: 'onboarded' } },
            { status: { eq: 'approved' } },
            { status: { eq: 'active' } },
            { status: { eq: 'suspended' } },
            { status: { eq: 'flagged' } },
          ],
        },
      });

      const data = await Promise.all(participants.map(async (participant) => {
        // get approved date from events
        const eventTo = participant.firstDataReceivedDate || moment.utc().format();
        const events = await asyncListAll(getEventsByUserByTimestamp,
          { username: participant.username, timestamp: { between: [participant.createdAt, eventTo] }, filter: { eventName: { eq: 'MODIFY' } } });
        const participantEvents = events.filter(({ key }) => key.match(/^Participant/));
        const approvedEvent = participantEvents.find(({ diff }) => {
          return diff.find(({ key, new: newValue }) => key === 'status' && newValue === '"approved"');
        });

        return participant.vehicles.items.map((vehicle) => {
          return mapFields(participant, vehicle, approvedEvent);
        });
      }));

      return data.flat();
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Participant Enrollment Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`ENROLLMENT MONITORING_Date ${moment().format('MM/DD/YYYY')}.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
