import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import VehicleDetailsTooltip from 'pages/Admin/components/VehicleDetailsTooltip';

import {
  asyncListAll,
} from 'utilities/graph';
import {
  listMileageReportingOptions,
} from './graphql';

import Table from 'components/Table';

export default function MRODevices({ username = null }) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [mros, setMros] = useState([]);

  const tableColumns = [
    {
      name: 'deviceSerialNumber',
      label: 'Serial Number',
    }, {
      name: 'shippedDate',
      label: 'Shipped Date',
      type: 'date',
    }, {
      name: 'deliveredDate',
      label: 'Delivered Date',
      type: 'date',
    }, {
      name: 'activationDate',
      label: 'Activation Date',
      type: 'date',
    }, {
      name: 'lastReportedDate',
      label: 'Last Report At',
      type: 'datetime',
    }, {
      name: 'vehicle.vin',
      label: 'Vehicle Details',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite(dataIndex) {
          const mro = mros ? mros[dataIndex] : null;
          if (!mro || !mro.vehicle) {
            return;
          }

          const { vehicle = null } = mro;
          return (
            <VehicleDetailsTooltip
              username={username}
              vehicle={vehicle}
            />
          );
        },
      },
    }, {
      name: 'gpsEnabled',
      label: 'GPS Enabled',
      type: 'checkbox',
    }];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const mroRecords = await asyncListAll(
          listMileageReportingOptions,
          undefined, { bypassCache: true },
        );

        const hydratedMros = mroRecords.map((mro) => {
          mro.participant.fullName = `${mro.participant.firstName} ${mro.participant.lastName}`;
          return mro;
        });

        if (username) {
          const userMros = hydratedMros.filter(({ username: mroUsername }) => {
            return mroUsername === username;
          });
          setMros(userMros);
        } else {
          setMros(hydratedMros);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username]);

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  const tableOptions = {
    download: false,
    filter: false,
  };

  return (
    <div className={classes.root}>
      <Table
        title="My Reporting Devices"
        options={tableOptions}
        columns={tableColumns}
        data={mros}
      />
    </div>
  );
}

MRODevices.propTypes = {
  username: PropTypes.string,
};
