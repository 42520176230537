/* eslint-disable max-len */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useStyles } from './commonStyles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Logo from 'components/Logo';

import ControlledInput from 'components/Form/ControlledInput';

import {
  agreeToTerms,
  updateParticipant,
} from 'graphql/mutations';
import {
  asyncRetryMutation,
} from 'utilities/graph';
import { APP } from 'utilities/constants';

const RegisterAgreements = ({
  participant,
  username,
  onPreviousStep,
  onCompleteStep,
}) => {
  const classes = useStyles();

  // preference form state
  const { control, errors, handleSubmit, formState, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { isSubmitting } = formState;
  const [error, setError] = useState(false);

  const [isGovernmentEmployee, setIsGovernment] = useState(false);
  const [isCaliforniaElected, setIsCalifornia] = useState(false);

  const agreementsInputs = [{
    type: 'select',
    name: 'isGovernmentEmployee',
    label: 'Yes or No',
    required: true,
    invalidText: 'You must select Yes or No',
    inputProps: {
      onChange: async (event) => {
        try {
          // console.log('target1', event.target);
          const checked = ((event.target.value == 1) ? true : false);
          setValue('isGovernmentEmployee', checked);
          setIsGovernment(checked);
        } catch (e) {
          setIsGovernment(false);
          console.error(e);
        }
      },
    },
    options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }],
    size: 'small',
  }, {
    type: 'select',
    name: 'isCaliforniaElected',
    label: 'Yes or No',
    required: true,
    invalidText: 'You must select Yes or No',
    inputProps: {
      onChange: async (event) => {
        try {
          // console.log('target2', event.target);
          const checked = ((event.target.value == 1) ? true : false);
          setValue('isCaliforniaElected', checked);
          setIsCalifornia(checked);
        } catch (e) {
          setIsCalifornia(false);
          console.error(e);
        }
      },
    },
    options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }],
    size: 'small',
  }, {
    type: 'checkbox',
    name: 'agreeGlobalParticipantAgreement',
    label: '3. By clicking here you agree to:',
    required: true,
    invalidText: 'You must accept all agreements',
  }, {
    type: 'checkbox',
    name: 'agreeGlobalPrivacyPolicy',
    label: '4. By clicking here you agree to:',
    required: true,
    invalidText: 'You must accept all agreements',
  }];

  async function handleAgreements() {
    try {
      await Promise.all([
        asyncRetryMutation(agreeToTerms, {
          input: {
            username,
            agreementDate: new Date().toISOString(),
            agreements: [{
              name: 'agreeGlobalParticipantAgreement',
              version: '0.0.1',
            },
            {
              name: 'agreeGlobalPrivacyPolicy',
              version: '0.0.1',
            }],
          },
        }),
      ]);

      await asyncRetryMutation(updateParticipant, {
        input: {
          username,
          flags: {
            'isGovernmentEmployee': isGovernmentEmployee,
            'isCaliforniaElected': isCaliforniaElected,
          },
          updatedBy: localStorage.getItem('ruc:username'),
        },
      });

      onCompleteStep();
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <div className={classes.paper}>
      <Logo width={250} fullColor display='block' margin='auto' />
      <Typography component="h1" variant="h5">Agreements</Typography>
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleAgreements)}
        noValidate
      >
        <Grid container spacing={2} justify="center" align="center">

          <Grid item xs={12}>
            <Grid container spacing={0}>
              {agreementsInputs.map((input, index) => {
                return (
                  <Grid item xs={12} key={index} style={{ textAlign: 'left' }}>
                    { (input.name == 'isGovernmentEmployee') &&
                      <React.Fragment>
                        <Grid container style={{ marginBottom: 16, marginTop: 16 }}>
                          <Grid item xs={12} align="center" style={{ textAlign: 'left' }}>
                            1. Are you an employee or contractor of the California State Transportation Agency or any of their subsidiary agencies or departments?
                            <ul className={classes.condensedList} style={{ marginTop: 10 }}>
                              <li>Board of Pilot Commissioners</li>
                              <li>California Highway Patrol</li>
                              <li>California Transportation Commission</li>
                              <li>Department of Transportation (Caltrans)</li>
                              <li>Department of Motor Vehicles (DMV)</li>
                              <li>High-Speed Rail Authority</li>
                              <li>Office of Traffic Safety</li>
                              <li>New Motor Vehicle Board</li>
                            </ul>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                    { (input.name == 'isCaliforniaElected') &&
                      <React.Fragment>
                        <Grid container style={{ marginBottom: 16, marginTop: 16, textAlign: 'left' }}>
                          <Grid item xs={12} align="center" style={{ textAlign: 'left' }}>
                            2. Are you a member of the California state legislature or an elected official?
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                    { (input.name == 'isCaliforniaElected') &&
                        <React.Fragment>
                          <Grid container style={{ marginBottom: 16, marginTop: 16, textAlign: 'left' }}>
                            <Grid item xs={12} align="center" style={{ textAlign: 'left' }}>
                              Please note: If you responded “Yes” to Question 1 or 2, you are not eligible to earn up to $400 in gift cards for participating in the Pilot.
                            </Grid>
                          </Grid>
                        </React.Fragment>
                    }
                    { (input.name == 'agreeGlobalParticipantAgreement') &&
                        <>
                          <ul className={classes.condensedList}>
                            <li>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${APP.LINK_PARTICIPANT_AGREEMENT}`}>
                                Participant Agreement
                              </a>
                            </li>
                          </ul>
                        </>
                    }
                    { (input.name == 'agreeGlobalPrivacyPolicy') &&
                        <>
                          <ul className={classes.condensedList}>
                            <li>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${APP.LINK_PRIVACY_POLICY}`}>
                                Privacy Policy
                              </a>
                            </li>
                          </ul>
                        </>
                    }
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button
              type="button"
              size="large"
              fullWidth
              variant="contained"
              color="inherit"
              className={classes.secondaryAction}
              onClick={() => {
                onPreviousStep();
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || !formState.isValid}
            >
              Complete
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={error!=false}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div >
  );
};

RegisterAgreements.propTypes = {
  participant: PropTypes.object,
  username: PropTypes.string,
  onPreviousStep: PropTypes.func,
  onCompleteStep: PropTypes.func,
};

export default RegisterAgreements;
