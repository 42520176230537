import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncGet, asyncListAll } from 'utilities/graph';
import { convertFuel, convertMileage } from 'utilities/format';

import { listTripsWithVehicles } from './graphql';
import { getParticipant } from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function mapFields(participant, trip, vehicle, segmentTotals, adjustment) {
  const id = `${participant.accountNo}-${vehicle.vin}`;
  let mroCertId = '';
  switch (participant.mroDevicePreference) {
    case 'automatedWithLocation':
      mroCertId = '5';
      break;
    case 'automatedWithoutLocation':
      mroCertId = '4';
      break;
  }

  return {
    'RecordID': id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'VIP': participant.flags.isVIP || false,
    'VIN': vehicle.vin,
    'Vehicle Registration State': vehicle.registrationState,
    'Vehicle Fuel Type': vehicle.type,
    'Vehicle Fuel Economy': vehicle.epaVehicleCombinedMpg,
    'MRO ID': vehicle.mro ? vehicle.mro.deviceSerialNumber : '',
    'MRO Type': vehicle.mro ? vehicle.mro.mroDevicePreference : '',
    'MRO Cert ID': mroCertId,
    'TRIP_ID': trip.id,
    'TRIP_Start Date': trip.tsStart,
    'TRIP_End Date': trip.tsEnd,
    'TRIP_Total Mileage': convertMileage(trip.distMro, 'mi'),
    'TRIP_Total Chargeable Mileage': segmentTotals.chargeableMileage,
    'TRIP_Total Non-Chargeable Mileage': segmentTotals.nonChargeableMileage,
    'TRIP_Total Non-Chargeable Mileage_CA': segmentTotals.nonChargeableMileageCA,
    'TRIP_Total RUC': (trip.mileageFeeCents / 100).toFixed(2),
    'TRIP_Total Fuel Usage': convertFuel(trip.fuel, 'gal'),
    'TRIP_Total Fuel Tax Credit': (trip.fuelFeeCents / 100).toFixed(2),
    'TRIP_Net RUC Revenue': vehicle.type === 'electric' ? '' : ((trip.mileageFeeCents - trip.fuelFeeCents) / 100).toFixed(2),
    'TRIP_Adjustment ID': adjustment == undefined ? '' : adjustment.id,
    'TRIP_Adjustment Date': adjustment == undefined ? '' : adjustment.createdAt,
    'TRIP_Adjustment Reason': adjustment == undefined ? '' : adjustment.note,
    'TRIP_Adjustment_Total Mileage': adjustment == undefined ? '' : convertMileage(adjustment.adjMileage, 'mi'),
    'TRIP_Adjustment_Total RUC': adjustment == undefined ? '' : (adjustment.adjMileageFeeCents / 100).toFixed(2),
    'TRIP_Adjustment_Total Fuel Usage': adjustment == undefined ? '' : convertFuel(adjustment.adjFuel, 'gal'),
    'TRIP_Adjustment _Total Fuel Tax Credit': adjustment == undefined ? '' : (adjustment.adjFuelFeeCents / 100).toFixed(2),
    'TRIP_Adjustment_Total Net RUC Revenue':
      adjustment == undefined || vehicle.type === 'electric' ? '' : ((adjustment.adjMileageFeeCents - adjustment.adjFuelFeeCents) / 100).toFixed(2),
  };
}

export default function BillingTripReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      const participants = {};
      const trips = await asyncListAll(listTripsWithVehicles, { filter: { createdAt: { between: [from, to] } } });
      const data = await Promise.all(trips.map(async (trip) => {
        participants[trip.username] = participants[trip.username] || await asyncGet(getParticipant, { username: trip.username });
        const participant = participants[trip.username].data.getParticipant;

        if (trip.vehicle) {
          const vehicle = trip.vehicle;
          const segments = trip.tripSegments.items;
          const segmentTotals = {
            chargeableMileage: segments.reduce((acc, { mileage, type, stateCode }) => {
              const chargeable = (vehicle.mroType === 'automatedWithLocation' &&
                type === 'state' && stateCode === 'CA') || vehicle.mroType !== 'automatedWithLocation';
              return acc + (chargeable ? convertMileage(mileage, 'mi') : 0);
            }, 0),
            nonChargeableMileage: segments.reduce((acc, { mileage, type, stateCode }) => {
              const nonChargeable = vehicle.mroType === 'automatedWithLocation' && (type !== 'state' || stateCode !== 'CA');
              return acc + (nonChargeable ? convertMileage(mileage, 'mi') : 0);
            }, 0),
            nonChargeableMileageCA: segments.reduce((acc, { mileage, type, stateCode }) => {
              const nonChargeable = vehicle.mroType === 'automatedWithLocation' && type !== 'state' && stateCode === 'CA';
              return acc + (nonChargeable ? convertMileage(mileage, 'mi') : 0);
            }, 0),
          };

          const rows = [mapFields(participant, trip, vehicle, segmentTotals, null)];
          return rows.concat(trip.adjustments.items.map((adjustment) => {
            return mapFields(participant, trip, vehicle, segmentTotals, adjustment);
          }));
        } else {
          return [];
        }
      }));

      return data.flat();
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Billing Trips Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Export_Billing_Trips.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
