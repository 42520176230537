/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import L from 'leaflet';
import {
  Map,
  LayersControl,
  // TileLayer,
  Polyline,
  CircleMarker,
  Marker,
  Popup,
  Polygon,
  FeatureGroup,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import { GoogleLayer } from 'react-leaflet-google-v2';
import p from '@mapbox/polyline';
import moment from 'moment-timezone';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { switchCoordinatesLatLng } from 'utilities/map';
import { formatMileage } from 'utilities/format';

import MeasureControl from './MeasureControl';
import SegmentList from './SegmentList';
import {
  listTripBins,
} from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import tollPlazas from './tollPlazas.json';

const { BaseLayer } = LayersControl;

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    flex: 1,
  },
  actionContainer: {
    padding: theme.spacing(2),
  },
}));

const colorMapping = {
  0: '#CD6155',
  1: '#16A085',
  2: '#AF7AC5',
  3: '#F5B041',
  4: '#21618C',
  5: '#B03A2E',
  6: '#1D8348',
  7: '#515A5A',
  8: '#884EA0',
  9: '#16A085',
};

tollPlazas.forEach((item) => {
  item.key = `${item.assetId} (${item.direction})`;
  switch (item.direction) {
    case 'N':
      item.color = colorMapping[9];
      break;
    case 'S':
      item.color = colorMapping[8];
      break;
    case 'E':
      item.color = colorMapping[7];
      break;
    case 'W':
    default:
      item.color = colorMapping[6];
      break;
  }
});

const tolls = tollPlazas.reduce((tolls, item) => {
  if (!tolls.includes(item.key)) {
    tolls.push(item.key);
  }
  return tolls;
}, []).sort((a, b) => a > b ? 1 : -1);
const DEFAULT_OPACITY = 0.8;

let reactFGref;

export default function TripVisualization({
  tripId,
  polyline = '',
  segments = [],
  cordons: inCordons,
  tods = [],
  height = 800,
  hideEditButton = true,
  hideSegments: inHideSegmentList = false,
  hideActions = false,
  onPolylineUpdate,
  viewer = 'admin',
}) {
  const classes = useStyles();
  const [mapElement, setMapElement] = useState();
  const [polylineElement, setPolylineElement] = useState();
  const [checked, setChecked] = useState([]);
  const [cordons, setCordons] = useState([]);
  const [zones, setZones] = useState([]);
  const [showPolyline, setShowPolyline] = useState(true);
  const [showBins, setShowBins] = useState(false);
  const [hasFetchedBins, setHasFetchedBins] = useState(false);
  const [bins, setBins] = useState();
  const [coordinates, setCoordinates] = useState([]);
  const [checkedTolls, setCheckedTolls] = useState([]);
  const [hideSegmentList, setHideSegmentList] = useState(inHideSegmentList);
  const [hasTollSegments, setHasTollSegments] = useState(false);

  const onEdited = async (e) => {
    let encodedPolylineString;
    e.layers.eachLayer((layer) => {
      const { coordinates } = layer.toGeoJSON().geometry;
      encodedPolylineString = p.encode(switchCoordinatesLatLng(coordinates));
    });

    if (onPolylineUpdate) {
      onPolylineUpdate(encodedPolylineString);
    }
  };

  const getGeoJson = () => {
    const geojson = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: {
          width: 5,
          color: '#2874A6',
        },
        geometry: {
          type: 'LineString',
          coordinates: switchCoordinatesLatLng(coordinates),
        },
      }],
    };

    return new L.GeoJSON(geojson, {
      style: (feature) => {
        return feature.properties;
      },
    });
  };

  const onFeatureGroupReady = (inReactFGref) => {
    if (reactFGref || !inReactFGref || !inReactFGref.leafletElement) return;

    reactFGref = inReactFGref;

    const leafletGeoJSON = getGeoJson();

    leafletGeoJSON.eachLayer((layer) => {
      reactFGref.leafletElement.addLayer(layer);
    });

    const bounds = reactFGref.leafletElement.getBounds();
    if (mapElement && bounds._northEast) {
      mapElement.fitBounds(bounds, { padding: [50, 50] });
    }
  };

  // map rendering configurations based on viewer
  useEffect(() => {
    if (viewer === 'admin') {
      return;
    }
    const displayMethod = localStorage.getItem('ruc:configuration:TRIP_DEFAULT_DISPLAY') || 'route';
    if (displayMethod === 'route') {
      setShowPolyline(true);
      setShowBins(false);
      setHideSegmentList(false);
    } else {
      setShowPolyline(false);
      setShowBins(true);
      setHideSegmentList(true);
    }
  }, [viewer]);

  // append polylines to map
  useEffect(() => {
    const coords = p.decode(polyline);
    setCoordinates(coords);
  }, [polyline]);

  // append cordons to map
  useEffect(() => {
    if (!inCordons || !Array.isArray(inCordons) || inCordons.lenth === 0) return;

    setCordons(inCordons);

    const allZones = [];
    inCordons.forEach(({ name: cordonName, zones }) => {
      zones.forEach((zone) => {
        zone.coordinates = switchCoordinatesLatLng(p.decode(zone.polyline));
        zone.label = `${cordonName}: ${zone.name}`;
        allZones.push(zone);
      });
    });
    setZones(allZones);
  }, [inCordons]);

  useEffect(() => {
    if (showBins && !hasFetchedBins && mapElement) {
      (async () => {
        const data = await asyncListAll(listTripBins, { tripId });
        setBins(data);
        setHasFetchedBins(true);

        if (viewer === 'admin') {
          return;
        }

        if (data.length > 0) {
          const bounds = L.latLngBounds(data.map(({ lat, lng }) => {
            return [lat, lng];
          }));
          console.log('TripVisualization useEffect data/bounds:', data, bounds);
          mapElement.fitBounds(bounds, { padding: [50, 50] });
        } else {
          // fallback to use polyline bins
          const fallbackBins = coordinates.map((coord) => {
            return { lat: coord[0], lng: coord[1] };
          });
          setBins(fallbackBins);

          const bounds = L.latLngBounds(fallbackBins.map(({ lat, lng }) => {
            return [lat, lng];
          }));
          console.log('TripVisualization useEffect bounds:', bounds);
          mapElement.fitBounds(bounds, { padding: [50, 50] });
        }
      })();
    }
  }, [tripId, showBins, hasFetchedBins, mapElement, viewer, coordinates]);

  useEffect(() => {
    if (mapElement && polylineElement) {
      try {
        mapElement.fitBounds(polylineElement.getBounds(), { padding: [50, 50] });
      } catch (e) {
        // console.log(e);
      }
    }
  }, [mapElement, polylineElement, coordinates]);

  useEffect(() => {
    if (!segments || segments.length === 0) return;

    segments.forEach((segment, index) => {
      segment.color = colorMapping[index];
    });

    setChecked(segments.map(({ id }) => id));
    setHasTollSegments(segments.filter(({ type }) => {
      return type === 'toll';
    }).length > 0);
  }, [segments]);

  useEffect(() => {
    return () => {
      reactFGref = undefined;
    };
  }, []);

  if (coordinates.length <= 1) return null;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={hideSegmentList ? 12 : 9}>
        <div className={classes.mapContainer} style={{ height }}>
          <Map
            zoom={10}
            ref={(ref) => {
              if (!ref || !ref.leafletElement) return;
              setMapElement(ref.leafletElement);
            }}
          >
            <LayersControl position='topright'>
              {
                <BaseLayer name="Google Maps - ROADMAP" checked={true}>
                  <GoogleLayer googlekey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} maptype="ROADMAP" />
                </BaseLayer>
              }
              {
                ['SATELLITE', 'HYBRID'].map((type, index) => (
                  <BaseLayer key={index} name={`Google Maps - ${type}`}>
                    <GoogleLayer googlekey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} maptype={type} />
                  </BaseLayer>
                ))
              }
            </LayersControl>
            {!hideEditButton && <FeatureGroup
              ref={(ref) => onFeatureGroupReady(ref)}
            >
              <EditControl
                position='topright'
                onEdited={onEdited}
                draw={{
                  polyline: false,
                  polygon: false,
                  rectangle: false,
                  circle: false,
                  marker: false,
                  circlemarker: false,
                }}
              />
            </FeatureGroup>}
            <MeasureControl />
            {zones.map(({ label, coordinates }, index) => (
              <Polygon
                key={index}
                positions={coordinates}
                weight={1}
              >
                <Popup>
                  {label}
                </Popup>
              </Polygon>
            ))}
            {!hideSegmentList && segments &&
              segments
                .filter(({ id }) => checked.includes(id))
                .map((segment, index) => (
                  <React.Fragment key={index}>
                    {segment.polylines.map((polyline, subindex) => (
                      <Polyline
                        key={subindex}
                        positions={p.decode(polyline)}
                        opacity={DEFAULT_OPACITY}
                        color={segment.color}
                        weight={20}
                        lineCap="butt"
                        lineJoin="miter" // round
                      >
                        <Popup>
                          {segment.state} - {segment.type} <br />
                          {formatMileage(segment.mileage, 'mi')}
                        </Popup>
                      </Polyline>))}
                  </React.Fragment>
                ))}

            {showPolyline && coordinates.length > 0 &&
              <Polyline
                ref={(ref) => {
                  if (!ref || !ref.leafletElement) return;
                  setPolylineElement(ref.leafletElement);
                }}
                positions={coordinates}
                weight={hideEditButton ? 5 : 1}
                color={hideEditButton ? '#2874A6' : '#666666'}
                dashArray={hideEditButton ? false : [5, 5]}
              />
            }

            {showPolyline && segments && segments.filter(({ id }) => checked.includes(id)).length > 0 &&
              <Polyline
                positions={coordinates}
                weight={3}
                color={'#F4F6F6'}
              />}

            {showBins && bins && bins.map((bin, index) => (
              <Marker position={[bin.lat, bin.lng]} key={index}>
                {bin.timeZone && <Popup>
                  {moment(bin.timestamp).tz(bin.timeZone).format('YYYY/MM/DD h:mm a')} ({bin.timeZone})
                </Popup>}
              </Marker>
            ))}

            {hasTollSegments && tollPlazas && tollPlazas.filter(
              ({ key }) => checkedTolls.includes(key),
            ).map(({ assetId, id, lat, lon, direction, color }, index) => (
              <CircleMarker key={index} center={[lat, lon]} radius={15} color={color} weight={1} fill={true} fillColor={color} fillOpacity={0.5}>
                <Popup>
                  {assetId} {id} {direction}
                </Popup>
              </CircleMarker>
            ))}

            {hasTollSegments && tollPlazas
              .filter(({ id }) => segments.some(({ tollEntryId, tollExitId }) => tollEntryId === id || tollExitId === id))
              .map(({ assetId, id, lat, lon, direction, color }, index) => (
                <CircleMarker key={index} center={[lat, lon]} radius={15} color={color} weight={1} fill={true} fillColor={color} fillOpacity={0.5}>
                  <Popup>
                    {assetId} {id} {direction}
                  </Popup>
                </CircleMarker>
              ))}

            <CircleMarker center={coordinates[0]} radius={5} color="#ffffff" weight={1} fill={true} fillColor="green" fillOpacity={1}>
              <Popup>
                Start
              </Popup>
            </CircleMarker>
            <CircleMarker center={coordinates[coordinates.length - 1]} radius={5} color="#ffffff" weight={1} fill={true} fillColor="red" fillOpacity={1}>
              <Popup>
                End
              </Popup>
            </CircleMarker>
          </Map>
        </div>
        {!hideActions &&
          <div>
            {viewer === 'admin' &&
              <div className={classes.actionContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBins}
                      onChange={() => setShowBins(!showBins)}
                      name="showBins"
                    />
                  }
                  label="Trip Bins"
                />
              </div>}
          </div>}
      </Grid>
      {!hideSegmentList &&
        <Grid item xs={12} md={3}>
          <SegmentList
            segments={segments}
            cordons={cordons}
            tods={tods}
            onUpdate={setChecked}
          />
        </Grid>}
      {hasTollSegments && (
        <Grid item xs={12}>
          <Typography id="discrete-slider-small-steps" gutterBottom>
            Toll ways
          </Typography>
          <Grid container spacing={1}>
            {tolls.map((toll) => (
              <Grid item xs={6} md={2} key={toll}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedTolls.includes(toll)}
                      onChange={() => {
                        const index = checkedTolls.indexOf(toll);
                        if (index === -1) {
                          checkedTolls.push(toll);
                        } else {
                          checkedTolls.splice(index, 1);
                        }
                        setCheckedTolls([...checkedTolls]);
                      }}
                      name="setCheckedTolls"
                    />
                  }
                  label={toll}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

TripVisualization.propTypes = {
  tripId: PropTypes.string,
  polyline: PropTypes.string,
  bins: PropTypes.array,
  segments: PropTypes.array,
  height: PropTypes.number,
  cordons: PropTypes.array,
  tods: PropTypes.array,
  hideSegments: PropTypes.bool,
  hideActions: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  onPolylineUpdate: PropTypes.func,
  viewer: PropTypes.string,
};
