import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  connect,
  rucScope,
  vehicleIsSmartcarCompatible,
} from 'utilities/smartcar';

import { useStyles } from './commonStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Logo from 'components/Logo';

import DeviceExplanation from './DeviceExplanation';
import ControlledInput from 'components/Form/ControlledInput';
import useScreenView from 'utilities/useScreenView';
import { mroDevicePreferences } from 'utilities/constants';
import {
  asyncListAll,
  asyncRetryMutation,
} from 'utilities/graph';
import {
  listPilotPrograms,
} from 'graphql/queries';
import {
  updateParticipant,
  createParticipantConnection,
  updateVehicle,
  vinCheck,
} from 'graphql/mutations';

const RegisterReportingOptions = ({
  cognitoUser,
  onPreviousStep,
  onCompleteStep,
  participantPilotProgram,
  vehicle,
}) => {
  const classes = useStyles();
  const { size } = useScreenView();

  // props
  const { username } = cognitoUser;

  // preference form state
  const { control, errors, handleSubmit, formState, watch, setValue } = useForm();
  const { isSubmitting } = formState;
  const watchMroDevicePreference = watch('mroDevicePreference');

  // overall state
  const [error, setError] = useState(false);
  const [pilotPrograms, setPilotPrograms] = useState([]);
  const [smartcarConnectComplete, setSmartcarConnectComplete] = useState(false);
  const [reportingOptions, setReportingOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);

  useEffect(() => {
    (async () => {
      const result = await asyncListAll(listPilotPrograms, null, { bypassCache: true });
      setPilotPrograms(result);
    })();
  }, []);

  // determine available options and default selected
  useEffect(() => {
    (async () => {
      if (reportingOptions.length > 0) return;

      const options = mroDevicePreferences;
      options.forEach((_, index) => {
        options[index].disabled = false;
      });

      const defaultOption = '';

      // electric vehicles should disable device options and select telematics
      if (vehicle.type === 'electric') {
        options.filter(({ value }) => value !== 'telematics').forEach((option) => {
          option.disabled = true;
        });
      }

      // all non-standard MBUF programs require automated device with location
      if (
        participantPilotProgram.shortName === 'MBUF+TF' ||
        participantPilotProgram.shortName === 'MBUF+ToD' ||
        participantPilotProgram.shortName === 'MBUF+C'
      ) {
        options.forEach((option) => {
          option.disabled = (option.value !== 'automatedWithLocation') ? true : false;
        });
      }

      // RUC-989 Use smartcar api to validate vehicles
      // disable telematics for known incompatible vehicles
      let smartcarCompatible;
      let vinCheckErrors;
      try {
        ({
          data: {
            vinCheck: {
              smartcarCompatible = false,
            },
          },
          errors: vinCheckErrors,
        } = await asyncRetryMutation(vinCheck, {
          input: {
            vin: vehicle.vin,
            source: 'smartcar',
          },
        }));
      } catch (e) {
        smartcarCompatible = false;
        vinCheckErrors = e;
      }

      // Fallback to use static check if the api failed.
      const compatible = (!vinCheckErrors) ?
        smartcarCompatible : vehicleIsSmartcarCompatible(
          vehicle.make.toUpperCase(),
          vehicle.year,
          vehicle.model.toUpperCase(),
        );

      if (!compatible) {
        options.filter(({ value }) => value === 'telematics').forEach((option) => {
          option.disabled = true;
        });
      }

      // RUC-1033 Use manual for all hydrogen vehicles
      if (vehicle.type === 'electric' && vehicle.subtype === 'hydrogen') {
        options.filter(({ value }) => value === 'telematics').forEach((option) => {
          option.disabled = true;
        });
      }

      // add manual option to all vehicles
      options.filter(({ value }) => value === 'manual').forEach((option) => {
        option.disabled = false;
      });

      setReportingOptions(options);
      setDefaultOption(defaultOption);
      setValue('mroDevicePreference', defaultOption);
    })();
  }, [reportingOptions, defaultOption, vehicle, participantPilotProgram]);

  // require reporting options.
  if (!reportingOptions.length) {
    return null;
  }

  const preferenceInputs = [{
    type: 'radio',
    name: 'mroDevicePreference',
    defaultValue: defaultOption,
    value: defaultOption,
    renderOptions: {
      row: size !== 'xs' ? true : false,
      labelPlacement: 'bottom',
    },
    options: reportingOptions,
    required: true,
    invalidText: 'Preferred reporting option is required',
  }];

  async function handleRegisterReportingOption({ mroDevicePreference, participantConnectionId = undefined }) {
    try {
      delete vehicle.createdAt;
      delete vehicle.updatedAt;
      const selectedPilot = mroDevicePreference === 'manual' ? pilotPrograms.find((program) => {
        return program.shortName === 'OB-MBUF';
      }) : participantPilotProgram;

      await Promise.all([
        asyncRetryMutation(updateParticipant, {
          input: {
            username,
            mroDevicePreference,
            participantPilotProgramId: selectedPilot.id,
            updatedBy: localStorage.getItem('ruc:username'),
          },
        }),
        asyncRetryMutation(updateVehicle, {
          input: Object.assign({}, vehicle, {
            participantConnectionId,
            mroType: mroDevicePreference,
          }),
        }),
      ]);

      onCompleteStep(selectedPilot);
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  async function handleSmartcarConnect() {
    try {
      const code = await connect(rucScope, {
        vehicleInfo: {
          make: vehicle.make.toUpperCase(),
        },
      });

      setSmartcarConnectComplete(true);

      const response = await asyncRetryMutation(createParticipantConnection, {
        input: {
          username,
          authorizationCode: code,
          resourceProvider: 'smartcar',
          vehicleId: vehicle.id,
        },
      });

      // update vehicle
      const {
        data: {
          createParticipantConnection: {
            id: participantConnectionId,
          },
        },
      } = response;

      handleRegisterReportingOption({
        mroDevicePreference: 'telematics',
        participantConnectionId,
      });
    } catch (e) {
      setSmartcarConnectComplete(false);

      // user denies access: "User denied access to the requested scope of permissions."
      console.warn(e.message);

      // disable telematics
      const options = Object.assign({}, reportingOptions);
      options.forEach((option) => {
        if (option.value === 'telematics') {
          option.disabled = true;
        }
      });

      setReportingOptions(options);
      setDefaultOption('automatedWithLocation');
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <div className={classes.paper}>
      <Logo width={250} fullColor display='block' margin='auto' />
      <Typography component="h1" variant="h5">Program Reporting Options</Typography>
      <DeviceExplanation
        preference={watchMroDevicePreference || defaultOption}
        showTeslaAlert={watchMroDevicePreference === 'telematics' && vehicle.make.toUpperCase() === 'TESLA'}
      />
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleRegisterReportingOption)}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item container xs={12} justify="center">
            <Box marginBottom={4}>
              {preferenceInputs.map((input, index) => {
                return (
                  <ControlledInput
                    key={index}
                    control={control}
                    errors={errors}
                    {...input}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="button"
              size="large"
              fullWidth
              variant="contained"
              color="inherit"
              className={classes.secondaryAction}
              disabled={smartcarConnectComplete}
              onClick={() => {
                onPreviousStep();
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={(e) => {
                if (
                  watchMroDevicePreference && watchMroDevicePreference === 'telematics' ||
                  !watchMroDevicePreference && defaultOption === 'telematics'
                ) {
                  e.preventDefault();
                  handleSmartcarConnect();
                }
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={error != false}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div >
  );
};

RegisterReportingOptions.propTypes = {
  cognitoUser: PropTypes.object,
  onPreviousStep: PropTypes.func,
  onCompleteStep: PropTypes.func,
  participantPilotProgram: PropTypes.object,
  vehicle: PropTypes.object,
};

export default RegisterReportingOptions;
