import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';
import { convertFuel, convertMileage } from 'utilities/format';

import {
  listTripsWithTransactions,
  listParticipantStatements,
} from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function mapFields(participant, vehicle, trip, statements, transaction) {
  const id = `${participant.accountNo}-${vehicle.vin}`;
  let statement;

  if (transaction != undefined && transaction.status === 'processed') {
    const transactionMonth = moment(transaction.createdAt).format('YYYY-MM');
    statement = statements.find(({ createdBy, month }) => createdBy === 'System' && month === transactionMonth);
  }

  return {
    'RecordID': id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'Email Address': participant.email,
    'VIN': vehicle.vin,
    'TRIP_ID': trip.id,
    'TRIP_Start Date': trip.tsStart,
    'TRIP_End Date': trip.tsEnd,
    'TRIP_Total Mileage': convertMileage(trip.distMro, 'mi'),
    'TRIP_Total RUC': (trip.mileageFeeCents / 100).toFixed(2),
    'TRIP_Total Fuel Usage': convertFuel(trip.fuel, 'gal'),
    'TRIP_Total Fuel Tax Credit': (trip.fuelFeeCents / 100).toFixed(2),
    'TRIP_Net RUC Revenue': vehicle.type === 'electric' ? '' : ((trip.mileageFeeCents - trip.fuelFeeCents) / 100).toFixed(2),
    'TRIP_Paid Date': statement != undefined ? statement.paidAt : '',
    'TRANSACTION_ID': transaction != undefined ? transaction.id : '',
    'Stmt Issue Date': statement != undefined ? statement.createdAt : '',
    'Stmt Month': statement != undefined ? statement.month : '',
  };
}

export default function BillingTripStatementReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      const [trips, statements] = await Promise.all([
        asyncListAll(listTripsWithTransactions, { filter: { createdAt: { between: [from, to] } } }),
        asyncListAll(listParticipantStatements, { filter: { createdBy: { eq: 'System' } } }),
      ]);

      const statementsByUsername = {};
      statements.forEach((statement) => {
        statementsByUsername[statement.username] = statementsByUsername[statement.username] || [];
        statementsByUsername[statement.username].push(statement);
      });

      const data = trips.map((trip) => {
        const vehicle = trip.vehicle;
        return trip.tripSegments.items.map((tripSegment) => {
          const participant = tripSegment.participant;
          const transaction = tripSegment.transaction;

          if (vehicle != undefined) {
            return mapFields(participant, vehicle, trip, statementsByUsername[trip.username], transaction);
          } else {
            return [];
          }
        });
      });

      return data.flat();
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Billing Trips Statement Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Billing_Trip_Statement_Report.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
