import React from 'react';
import VerticalTabs from 'components/Tab/VerticalTabs';
import MileageReports from './components/MileageReports';

export default function MileageReportAudit() {
  const tabs = [
    {
      label: 'Mileage Reports',
      component: <MileageReports />,
    },
  ];

  return (
    <VerticalTabs tabs={tabs} />
  );
}
