import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import { updateConfiguration } from 'graphql/mutations';
import {
  asyncListAll,
  asyncRetryMutation,
} from 'utilities/graph';

const title = 'System Configurations';
const description = '';

const CACHE_KEY = 'ConfigurationsTable-ListConfigurations';

function ConfigurationsTable({ viewer = 'admin' }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
    expandableRows: true,
    rowsPerPage: 100,
    isRowExpandable: () => false,
  };

  const onUpate = async (item, dataIndex) => {
    const input = {
      key: item.key,
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });
    await asyncRetryMutation(updateConfiguration, { input }, { clearCacheKeys: [CACHE_KEY] });

    localStorage.setItem(`ruc:configuration:${item.key}`, item.value);

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };


  useEffect(() => {
    (async () => {
      try {
        const records = (await asyncListAll( /* GraphQL */ `
          query ListConfigurations(
            $key: String
            $filter: ModelConfigurationFilterInput
            $limit: Int
            $nextToken: String
            $sortDirection: ModelSortDirection
          ) {
            listConfigurations(
              key: $key
              filter: $filter
              limit: $limit
              nextToken: $nextToken
              sortDirection: $sortDirection
            ) {
              items {
                key
                name
                description
                value
                options {
                  name
                  value
                }
              }
              nextToken
            }
          }
        `, undefined, { cacheKey: CACHE_KEY }));
        setData(records);

        const columns = [
          {
            name: 'key',
            label: 'Key',
            options: {
              display: false,
              filter: false,
              sort: true,
            },
          },
          {
            name: 'name',
            label: 'Name',
            options: {
              filter: false,
              sort: true,
            },
          },
          {
            name: 'description',
            label: 'Description',
            options: {
              filter: false,
              sort: true,
            },
          },
          {
            name: 'value',
            label: 'Value',
            edit: {
              type: (data) => {
                if (Array.isArray(data.options) && data.options.length > 0) {
                  return 'select';
                }
                return 'text';
              },
              menu: (data) => {
                if (!Array.isArray(data.options)) return [];
                return data.options.map(({ name, value }) => {
                  return { label: name, value };
                });
              },
            },
            options: {
              filter: false,
              sort: true,
            },
          },
        ];

        setColumns(columns);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onUpdateItem={onUpate}
      themeProps={{
        cell: {
          '&:nth-child(4)': {
            'max-width': '400px',
            'overflow': 'auto',
          },
        },
      }}
    />
  );
}

ConfigurationsTable.propTypes = {
  viewer: PropTypes.string,
};

export default ConfigurationsTable;
