import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import { listCordons } from 'graphql/queries';
import { updateCordon } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import {
  states,
} from 'utilities/constants';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import CordonVisualization from 'pages/Admin/components/CordonVisualization';
import LinkButton from 'components/Table/LinkButton';

const title = 'Cordons';
const description = '';

function CordonsTable({ viewer = 'admin' }) {
  const [data, setData] = useState([]);
  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData, rowMeta) {
      const { id } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns} colSpanPadding={2}>
          <CordonVisualization id={id} />
        </NestedTableContainer>
      );
    },
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        sort: true,
        customBodyRender(item) {
          const fullName = states[item];
          return fullName ? `${fullName} (${item})` : '';
        },
      },
    },
    {
      name: 'name',
      label: 'Name',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Description',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'days',
      label: 'Days',
      // edit: {
      //   type: 'currency',
      // },
      options: {
        filter: false,
        sort: true,
        customBodyRender(value = []) {
          return value.join(', ');
        },
      },
    },
    {
      name: 'internalStart',
      label: 'Start',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'internalEnd',
      label: 'End',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'entryFeeCents',
      label: 'Entry Fee',
      type: 'currency',
      edit: {
        type: 'currency',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'exitFeeCents',
      label: 'Exit Fee',
      type: 'currency',
      edit: {
        type: 'currency',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'centsPerMileageUnit',
      label: 'Cents per Mileage Unit',
      type: 'mileage',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'minimumDistanceInMileageUnit',
      label: 'Buffer: Min. Distance',
      type: 'mileage',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'minimumTotalDistanceInMileageUnit',
      label: 'Buffer: Min. Total Distance',
      type: 'mileage',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/cordon/${id}`}
              label="View Cordon details"
            />
          );
        },
      },
    },
  ];


  const onUpate = async (item, dataIndex) => {
    const input = {
      id: item.id,
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });
    await asyncRetryMutation(updateCordon, { input }, { clearCacheKeys: ['listCordons'] });

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };


  useEffect(() => {
    (async () => {
      try {
        const records = (await asyncListAll(listCordons));
        setData(records);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onUpdateItem={onUpate}
    />
  );
}

CordonsTable.propTypes = {
  viewer: PropTypes.string,
};

export default CordonsTable;
