import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncGet, asyncListAll } from 'utilities/graph';
import { convertFuel, convertMileage } from 'utilities/format';

import {
  listParticipantStatements,
  getParticipantWithVehicles,
} from './graphql';
import {
  listPaymentCards,
} from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const overdueDays = localStorage.getItem('ruc:configuration:BILLING_BALANCE_OVERDUE_DAYS');

function getMonthDateRange(date, format = '') {
  const start = moment(date).utcOffset(0);
  start.set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  return {
    start: start.format(format),
    end: start.add(1, 'months').format(),
  };
}

function mapFields(statement, participant, vehicle, hasCard, statementPeriod, tripSummary, adjSummary, vehicleSummary) {
  const id = `${participant.accountNo}-${vehicle.vin}`;
  let mroCertId = '';
  switch (participant.mroDevicePreference) {
    case 'automatedWithLocation':
      mroCertId = '5';
      break;
    case 'automatedWithoutLocation':
      mroCertId = '4';
      break;
  }

  const odo = vehicle.reports == undefined ? [] : vehicle.reports.sort((a, b) => {
    return moment(a.tsReportDate).isBefore(b.tsReportDate) ? -1 : 1;
  });
  const odoStart = odo.find(({ tsReportDate }) =>
    moment(tsReportDate).isAfter(statementPeriod.start) && moment(tsReportDate).isBefore(statementPeriod.end));
  const odoReverse = vehicle.reports == undefined ? [] : vehicle.reports.sort((a, b) => {
    return moment(a.tsReportDate).isAfter(b.tsReportDate) ? -1 : 1;
  });
  const odoEnd = odoReverse.find(({ tsReportDate }) =>
    moment(tsReportDate).isAfter(statementPeriod.start) && moment(tsReportDate).isBefore(statementPeriod.end));

  let daysToPay = '';
  if (statement.paidAt) {
    daysToPay = moment(statement.createdAt).diff(moment(statement.paidAt), 'days');
  }

  let chargeableMileage = '';
  let nonChargeableMileage = vehicleSummary == undefined ? '' : convertMileage(vehicleSummary.mileage, 'mi');
  let nonChargeableMileageCA = '';
  if (tripSummary) {
    const caTrips = tripSummary.find(({ stateCode }) => stateCode === 'CA');
    if (caTrips) {
      chargeableMileage = convertMileage(caTrips.publicMileage, 'mi');
      nonChargeableMileage -= chargeableMileage;
      nonChargeableMileageCA = convertMileage(caTrips.privateMileage, 'mi');
    }
  }

  const dueBy = moment(statement.createdAt).add(overdueDays, 'days');
  let amountPaidByDueDate = 0;
  if (statement.paidAt) {
    amountPaidByDueDate = daysToPay < overdueDays ? statement.paidAmount : 0;
  }

  return {
    'RecordID': id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'Payment Method Stored': hasCard,
    'RUC Group': participant.pilotProgram == undefined ? '' : participant.pilotProgram.shortName === 'MBUF+DR' ? 'Variable Rate' : 'Flat Rate',
    'VIP': participant.flags == undefined ? false : participant.flags.isVIP || false,
    'VIN': vehicle.vin,
    'Vehicle Registration State': vehicle.registrationState,
    'Vehicle Fuel Type': vehicle.type,
    'Vehicle Fuel Economy': vehicle.epaVehicleCombinedMpg,
    'MRO ID': vehicle.mro ? vehicle.mro.deviceSerialNumber : '',
    'MRO Type': vehicle.mro ? vehicle.mro.mroDevicePreference : '',
    'MRO Cert ID': mroCertId,
    'Stmt Month': statement.month,
    'Stmt Issue Date': statement.createdAt,
    'Stmt Due Date': dueBy.utc().format(),
    'MRO Start Report Date': odoStart == undefined ? '' : odoStart.tsReportDate,
    'ODO Start Value': odoStart == undefined ? '' : odoStart.odometer,
    'MRO End Report Date': odoEnd == undefined ? '' : odoEnd.tsReportDate,
    'ODO End Value': odoEnd == undefined ? '' : odoEnd.odometer,
    'Total Mileage': vehicleSummary == undefined ? '' : convertMileage(vehicleSummary.mileage, 'mi'),
    'Total Chargeable Mileage': chargeableMileage,
    'Total Non-Chargeable Mileage': nonChargeableMileage,
    'Total Non-Chargeable Mileage_CA': nonChargeableMileageCA,
    'Total RUC': vehicleSummary == undefined ? '' : vehicleSummary.mileageFee,
    'Total Fuel Usage': vehicleSummary == undefined ? '' : convertFuel(vehicleSummary.fuel, 'gal'),
    'Total Fuel Tax Credit': vehicle.type === 'electric' || vehicleSummary == undefined ? '' : Math.abs(vehicleSummary.fuelFee),
    'Total RIF Credit': vehicle.type !== 'electric' || vehicleSummary == undefined ? '' : Math.abs(vehicleSummary.fuelFee),
    'Net RUC Revenue': vehicleSummary == undefined ? '' : vehicleSummary.taxDifference,
    'Adjustment_Total Mileage': adjSummary == undefined ? '' : convertMileage(adjSummary.adjMileage, 'mi'),
    'Adjustment_Total RUC': adjSummary == undefined ? '' : adjSummary.adjMileageFee,
    'Adjustment_Total Fuel Usage': adjSummary == undefined ? '' : convertFuel(adjSummary.fuel, 'gal'),
    'Adjustment _Total Fuel Tax Credit': adjSummary == undefined ? '' : Math.abs(adjSummary.adjFuelFee),
    'Adjustment_Total Net RUC Revenue': adjSummary == undefined ? '' : adjSummary.adjMileageFee - Math.abs(adjSummary.adjFuelFee),
    'Statement Amount Payment Date': statement.paidAt || '',
    'Statement Amount Paid': statement.paidAmount || '',
    'Statement No. of Days to Pay': daysToPay,
    'Statement Amount Due Paid by Due Date': amountPaidByDueDate,
  };
}

export default function BillingMonthReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      const participants = {};
      const cards = {};

      const statements = await asyncListAll(listParticipantStatements, { filter: { periodFrom: { between: [from, to] }, createdBy: { eq: 'System' } } });
      const data = await Promise.all(statements.map(async (statement) => {
        participants[statement.username] = participants[statement.username] || await asyncGet(getParticipantWithVehicles, { username: statement.username });
        const participant = participants[statement.username].data.getParticipant;
        cards[statement.username] = cards[statement.usersname] || await asyncListAll(listPaymentCards, { username: statement.username, limit: 1 });
        const hasCard = cards[statement.username].length > 0;

        const statementPeriod = getMonthDateRange(statement.month);
        return participant.vehicles.items.map((vehicle) => {
          const tripSummary = statement.tripsSummary == undefined ? undefined : statement.tripsSummary.filter(({ vehicleId }) => vehicleId === vehicle.id);
          const adjSummary = statement.adjustmentsSummary == undefined ? undefined :
            statement.adjustmentsSummary.find(({ vehicleId }) => vehicleId === vehicle.id);
          const vehicleSummary = statement.vehiclesSummary == undefined ? undefined :
            statement.vehiclesSummary.find(({ vehicleId }) => vehicleId === vehicle.id);
          return mapFields(statement, participant, vehicle, hasCard, statementPeriod, tripSummary, adjSummary, vehicleSummary);
        });
      }));

      return data.flat();
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Billing Month Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Export_Billing_Month.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
