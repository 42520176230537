import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import { listTods } from 'graphql/queries';
import { updateTod } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';

const title = 'Time of Day';
const description = '';

function TodTable({ viewer = 'admin' }) {
  const [data, setData] = useState([]);
  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'days',
      label: 'Days',
      // edit: {
      //   type: 'currency',
      // },
      options: {
        filter: false,
        sort: true,
        customBodyRender(value = []) {
          return value.join(', ');
        },
      },
    },
    {
      name: 'internalStart',
      label: 'Start',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'internalEnd',
      label: 'End',
      edit: {
        type: 'string',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'centsPerMileageUnit',
      label: 'Cents per Mileage Unit',
      type: 'mileage',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const onUpate = async (item, dataIndex) => {
    const input = {
      id: item.id,
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });
    await asyncRetryMutation(updateTod, { input }, { clearCacheKeys: ['listTods'] });

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };


  useEffect(() => {
    (async () => {
      try {
        const records = (await asyncListAll(listTods));
        setData(records);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onUpdateItem={onUpate}
    />
  );
}

TodTable.propTypes = {
  viewer: PropTypes.string,
};

export default TodTable;
