import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { theme } from 'index.js';
import { ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import NP from 'number-precision';

import { useStyles } from 'pages/Auth/components/commonStyles';
import {
  listTripSegments,
  listMRORates,
  listTods,
  getTrip,
  getTripSegment,
  getMileageReportingOption,
  listCordons,
  listVehicles,
} from 'graphql/queries';
import { createTripAdjustment } from 'graphql/mutations';
import {
  asyncGet,
  asyncListAll,
  asyncRetryMutation,
} from 'utilities/graph';
import {
  formatFuel,
  formatMileage,
  convertMileage,
  convertFuel,
} from 'utilities/format';

const CreateTripAdjustmentDialog = ({ isOpen, onClose, tripId, tripSegmentId }) => {
  const classes = useStyles();

  const [tripSegments, setTripSegments] = useState([]);
  const [note, setNote] = useState('');
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tripSegmentsFields, setTripSegmentsFields] = useState([]);

  const reset = async () => {
    setTripSegments([]);
    setNote('');
    setTimeout(() => {
      setLastUpdatedAt(Date.now());
    });
  };

  const submit = async () => {
    try {
      setIsSubmitting(true);

      const promises = tripSegments
        .filter(({ isDirty }) => isDirty)
        .map(async (segment) => {
          const adjMileage = NP.minus(segment.mileageFormat, segment.cacheItems.mileageFormat);
          const adjFuel = NP.minus(segment.fuelFormat, segment.cacheItems.fuelFormat);

          const input = {
            tripId,
            username: segment.username,
            tripSegmentId: segment.id,
            tripSegmentDate: segment.createdAt,
            vehicleId: segment.vehicleId,
            type: segment.type,
            state: segment.state,
            stateCode: segment.stateCode,
            cordonId: segment.cordonId,
            cordonName: segment.cordonName,
            todId: segment.todId,
            todName: segment.todName,
            tollId: segment.tollId,
            adjMileage: segment.mileageIsConverted ? convertMileage(adjMileage, 'km') : adjMileage,
            adjMileageFeeCents: NP.minus(NP.times(segment.mileageFee, 100), (segment.mileageFeeCents || 0)),
            adjFuel: segment.fuelIsConverted ? convertMileage(adjFuel, 'li') : adjMileage,
            adjFuelFeeCents: NP.minus(NP.times(segment.fuelFee, 100), (segment.fuelFeeCents || 0)),
            adjCordonFeeCents: NP.minus(NP.times(segment.cordonFee, 100), (segment.cordonFeeCents || 0)),
            adjTodFeeCents: NP.minus(NP.times(segment.todFee, 100), (segment.todFeeCents || 0)),
            adjTollFeeCents: NP.minus(NP.times(segment.tollFee, 100), (segment.tollFeeCents || 0)),
            note,
            createdBy: localStorage.getItem('ruc:username'),
            createdAt: new Date().toISOString(),
            paymentStatus: 'pending',
          };

          const { data: { createTripAdjustment: savedItem } } = await asyncRetryMutation(createTripAdjustment, {
            input,
          });

          return savedItem;
        });
      const savedItems = await Promise.all(promises);

      onClose(savedItems);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!isOpen) return;

    const getTripSegments = async (tripSegmentId) => {
      if (tripSegmentId) {
        const { data: { getTripSegment: tripSegment } } = await asyncGet(getTripSegment, { tripId, id: tripSegmentId });
        return [tripSegment];
      } else {
        return asyncListAll(listTripSegments, { tripId }, { bypassCache: true });
      }
    };

    (async () => {
      setIsSubmitting(true);
      // console.log('CreateTripAdjustmentDialog: (INPUTS)', 'isOpen:', isOpen, 'tripId:', tripId, 'tripSegmentId:',
      //  tripSegmentId, 'lastUpdatedAt:', lastUpdatedAt);

      const [
        tripSegments,
        allMroRates,
        { data: { getTrip: trip } },
      ] = await Promise.all([
        getTripSegments(tripSegmentId),
        asyncListAll(listMRORates),
        asyncGet(getTrip, { id: tripId }),
      ]);
      // console.log('(2)', 'tripSegments:', tripSegments, 'allMroRates:', allMroRates, 'trip:', trip);

      const [
        vehicles,
      ] = await Promise.all([
        asyncListAll(listVehicles, { username: trip.username, sortDirection: 'DESC' }),
      ]);
      // console.log('(3)', 'vehicles:', vehicles);

      const [
        { data: { getMileageReportingOption: mro } },
        todRates,
        cordons,
      ] = await Promise.all([
        asyncGet(getMileageReportingOption, { username: trip.username, id: trip.mroId }),
        asyncListAll(listTods),
        asyncListAll(listCordons),
      ]);
      // console.log('(4)', 'todRates:', todRates, 'cordons:', cordons);

      const mileageUnit = localStorage.getItem('ruc:configuration:MILEAGE_UNIT') || 'km';
      const fuelUnit = localStorage.getItem('ruc:configuration:FUEL_UNIT') || 'l';

      const mroRates = allMroRates.filter(({ mroType }) => {
        return mro.gpsEnabled ? mroType === 'automatedWithLocation' : mroType === 'automatedWithoutLocation';
      });

      const formatCentsPerMileageUnit = (centsPerMileageUnit) => {
        if (mileageUnit === 'mi') {
          return convertMileage(centsPerMileageUnit, 'km');
        } else {
          return centsPerMileageUnit;
        }
      };

      const formatCentsPerFuelUnit = (centsPerFuelUnit) => {
        if (fuelUnit === 'gal') {
          return convertFuel(centsPerFuelUnit, 'li');
        } else {
          return centsPerFuelUnit;
        }
      };

      const tripSegmentsFields = [{
        type: 'number',
        key: 'mileageFormat',
        label: `Mileage (${mileageUnit})`,
        isDisabled: ({ type }) => {
          return type === 'toll';
        },
        show: () => true,
        onUpdate: (segment) => {
          let adjustingVehicle;
          if (trip.username) {
            console.log('Trip Adjustment - vehicles', vehicles);
            console.log('Trip Adjustment - segment:', segment);
            adjustingVehicle = vehicles.find((item) => item.id == segment.vehicleId);
            console.log('Trip Adjustment - adjustingVehicle:', adjustingVehicle);
          }

          const mroRate = mroRates.find(({ state }) => state === segment.stateCode);
          // Mileage portion
          const mileage = segment.mileageIsConverted ? convertMileage(segment.mileageFormat, 'km') : segment.mileageFormat;
          if (segment.todId) {
            const todRate = todRates.find(({ id }) => id === segment.todId);
            segment['todFee'] = parseFloat((mileage * todRate.centsPerMileageUnit / 100).toFixed(2));
          } else if (segment.cordonId) {
            const cordon = cordons.find(({ id }) => id === segment.cordonId);
            segment['cordonFee'] = parseFloat((mileage * cordon.centsPerMileageUnit / 100).toFixed(2));
          } else {
            segment['mileageFee'] = parseFloat((mileage * mroRate.centsPerMileageUnit / 100).toFixed(2));
          }

          // Fuel portion
          if (!adjustingVehicle) {
            alert('Cannot find the associated vehicle to adjust fuel');
            return segment;
          }
          // segment.mileageFormat - original milage format, km or mi
          const tempFuelRate = segment.fuelIsConverted ? adjustingVehicle.epaVehicleCombinedMpg : adjustingVehicle.epaVehicleCombinedKpl;
          const tempFuelUsed = parseFloat((segment.mileageFormat / tempFuelRate).toFixed(2)); // in metric OR imperial
          segment.fuelFormat = tempFuelUsed;
          const fuel = segment.fuelIsConverted ? convertFuel(segment.fuelFormat, 'li') : segment.fuelFormat;
          segment['fuelFee'] = parseFloat((fuel * mroRate.centsPerFuelUnit / 100).toFixed(2));
          console.log('FUEL ADJ1: Dist(km):', mileage, ', DistRate($cents/km):', mroRate.centsPerMileageUnit, ', Dist(km|mi):', segment.mileageFormat);
          console.log('FUEL ADJ2: DistPerFuel(kpl|mpg):', tempFuelRate, ', FuelRate($cents/l):', mroRate.centsPerFuelUnit);
          console.log('FUEL ADJ3: FuelAdj (l|gal):', tempFuelUsed, ', Fuel(l):', fuel, ', Fee($):', segment['fuelFee']);

          return segment;
        },
      }, {
        type: 'number',
        key: 'mileageFee',
        label: 'Road Charge',
        isDisabled: () => true,
        show: (segment) => !segment.todId && !segment.cordonId && !segment.tollId,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: (segment) => {
          let rate = '';
          if (!segment.todId && !segment.cordonId && !segment.tollId) {
            const mroRate = mroRates.find(({ state }) => state === segment.stateCode);
            rate = `($${formatCentsPerMileageUnit(mroRate.centsPerMileageUnit) / 100}/${mileageUnit})`;
          }
          return (<InputAdornment position="end">{rate}</InputAdornment>);
        },
      }, {
        type: 'number',
        key: 'fuelFormat',
        label: 'Fuel',
        isDisabled: ({ type }) => {
          return type === 'toll' || type === 'tod';
        },
        show: (segment) => !segment.todId && !segment.cordonId && !segment.tollId,
        onUpdate: (segment) => {
          const fuel = segment.fuelIsConverted ? convertFuel(segment.fuelFormat, 'li') : segment.fuelFormat;

          const mroRate = mroRates.find(({ state }) => state === segment.stateCode);
          segment['fuelFee'] = parseFloat((fuel * mroRate.centsPerFuelUnit / 100).toFixed(2));
          return segment;
        },
        endAdornment: <InputAdornment position="end">{fuelUnit}</InputAdornment>,
      }, {
        type: 'number',
        key: 'fuelFee',
        label: 'Fuel Tax Credits',
        isDisabled: () => true,
        show: (segment) => !segment.todId && !segment.cordonId && !segment.tollId,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: (segment) => {
          let rate = '';
          if (segment.fuelFee > 0) {
            const mroRate = mroRates.find(({ state }) => state === segment.stateCode);
            rate = `($${formatCentsPerFuelUnit(mroRate.centsPerFuelUnit) / 100}/${fuelUnit})`;
          }
          return (<InputAdornment position="end">{rate}</InputAdornment>);
        },
        // }, {
        //   type: 'number',
        //   key: 'cordonEnterFee',
        //   label: 'Cordon Enter Fee',
        //   isDisabled: () => true,
        //   startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }, {
        type: 'number',
        key: 'cordonFee',
        label: 'Cordon Fee',
        isDisabled: () => true,
        show: (segment) => segment.cordonId,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: (segment) => {
          let rate = '';
          if (segment.cordonId) {
            const cordon = cordons.find(({ id }) => id === segment.cordonId);
            rate = `($${formatCentsPerMileageUnit(cordon.centsPerMileageUnit) / 100}/${mileageUnit})`;
          }
          return (<InputAdornment position="end">{rate}</InputAdornment>);
        },
      }, {
        type: 'number',
        key: 'todFee',
        label: 'TOD Fee',
        isDisabled: () => true,
        show: (segment) => segment.todId,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: (segment) => {
          let rate = '';
          if (segment.todId) {
            const todRate = todRates.find(({ id }) => id === segment.todId);
            rate = `($${formatCentsPerMileageUnit(todRate.centsPerMileageUnit) / 100}/${mileageUnit})`;
          }
          return (<InputAdornment position="end">{rate}</InputAdornment>);
        },
      }, {
        type: 'number',
        key: 'tollFee',
        label: 'Toll Fee',
        isDisabled: () => true,
        show: (segment) => segment.tollId,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }];

      const promises = tripSegments.map(async (item) => {
        item.displayName = item.state || '';

        if (item.cordonId) {
          const { data: { getCordon: cordon } } = await asyncGet( /* GraphQL */ `
            query GetCordon($id: ID!) {
              getCordon(id: $id) {
                id
                state
                name
              }
            }
          `, { id: item.cordonId });
          item.displayName = cordon ? `${cordon.state} - ${cordon.name}` : item.cordonId;
          item.cordonName = item.displayName;
        }

        if (item.todId) {
          const { data: { getTod: tod } } = await asyncGet( /* GraphQL */ `
            query GetTod($id: ID!) {
              getTod(id: $id) {
                id
                name
              }
            }
          `, { id: item.todId });
          item.displayName = tod ? `${tod.name}` : item.todId;
          item.todName = item.displayName;
        }

        item.mileageFormat = formatMileage(item.mileage, mileageUnit);
        item.mileageIsConverted = item.mileageFormat !== item.mileage;
        item.mileageFee = item.mileageFeeCents / 100;
        item.fuelFormat = formatFuel(item.fuel, fuelUnit);
        item.fuelIsConverted = item.fuelFormat !== item.fuel;
        item.fuelFee = item.fuelFeeCents / 100;
        item.cordonFee = item.cordonFeeCents / 100;
        item.cordonEnterFee = item.cordonEnterFeeCents / 100;
        item.todFee = item.todFeeCents / 100;
        item.tollFee = (item.tollFeeCents || 0) / 100;
        item.cacheItems = tripSegmentsFields.reduce((obj, field) => {
          obj[field.key] = item[field.key];
          return obj;
        }, {});
        item.dirtyItems = {};
        return item;
      });

      setTripSegments(await Promise.all(promises));

      setTripSegmentsFields(tripSegmentsFields);
      setIsSubmitting(false);
    })();
  }, [isOpen, tripId, tripSegmentId, lastUpdatedAt]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        data-test-id="create-trip-adjustment-dialog"
        open={isOpen}
        onClose={() => onClose()}
        disableBackdropClick={true}
        maxWidth="lg"
      >
        <DialogContent>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">Trip Adjustment</Typography>
            <Grid container spacing={2}>
              {tripSegments.filter(({ type, stateCode }) => type === 'public' && stateCode === 'CA').map((segment, index) => (
                <Grid container item key={index} spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="p" variant="h5">
                      {segment.type.toUpperCase()}: {segment.displayName} ({(segment.percentage * 100).toFixed(0)}%)
                    </Typography>
                  </Grid>
                  {tripSegmentsFields.map(({ key, label, show, type, startAdornment, endAdornment, isDisabled, onUpdate }) => {
                    if (show && !show(segment)) return;
                    return (
                      <Grid item xs={12} md={3} key={key}>
                        <TextField
                          id={key}
                          name={label}
                          label={`${label}${segment.cacheItems[key] !== segment[key] ? ' (adjusted)' : ''}`}
                          type={type}
                          // defaultValue={segment[key]}
                          value={segment[key]}
                          fullWidth
                          disabled={isSubmitting || (isDisabled && isDisabled(segment))}
                          variant="outlined"
                          onChange={(e) => {
                            segment[key] = type === 'number' ? +(e.target.value) : e.target.value;
                            segment.isDirty = tripSegmentsFields.some(({ key }) => segment.cacheItems[key] !== segment[key]);

                            if (onUpdate) {
                              segment = onUpdate(segment);
                            }

                            setTripSegments([...tripSegments]);
                          }}
                          error={segment.cacheItems[key] !== segment[key]}
                          InputProps={{
                            startAdornment,
                            endAdornment: typeof endAdornment === 'function' ? endAdornment(segment) : endAdornment,
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ))}
              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id={'note'}
                  name={'note'}
                  label={'Note'}
                  type={'text'}
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  multiline
                  required={true}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
              </Grid>
              <Grid container item spacing={2} className={classes.submit}>
                <Grid item xs={12} md={2}>
                  <Button
                    type="button"
                    size="large"
                    variant="contained"
                    color="inherit"
                    disabled={isSubmitting}
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    type="button"
                    size="large"
                    color="inherit"
                    disabled={isSubmitting}
                    onClick={() => reset()}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={12} md={8} align="right">
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !tripSegments.some(({ isDirty }) => isDirty) || note === ''}
                    onClick={submit}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

CreateTripAdjustmentDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  tripId: PropTypes.string,
  tripSegmentId: PropTypes.string,
};

export default CreateTripAdjustmentDialog;
