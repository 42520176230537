import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import JSONPretty from 'react-json-pretty';

import Table from 'components/Table';
import { getWebhookEventsByStatusByCreatedAt } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';

const title = 'Webhook Events';
const description = '';

export default function EventTable({ viewer = 'admin' }) {
  const [webhookEvents, setWebhookEvents] = useState([]);

  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
  };

  const columns = [
    {
      name: 'createdAt',
      label: 'Timestamp',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'source',
      label: 'Source',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'payload',
      label: 'Payload',
      options: {
        filter: false,
        sort: false,
        customBodyRender(data) {
          return <JSONPretty id="json-pretty" data={data}></JSONPretty>;
        },
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const webhookEventStatus = ['pending', 'processed'];

        const aMonthAgo = moment().subtract(1, 'months').toISOString();

        let allWebhookEvents = [];

        await Promise.all(webhookEventStatus.map(async (status) => {
          const records = await asyncListAll(getWebhookEventsByStatusByCreatedAt, {
            status,
            createdAt: {
              gt: aMonthAgo,
            },
          });

          allWebhookEvents = [...allWebhookEvents, ...records];
        }));
        setWebhookEvents(allWebhookEvents.sort(sortBy('createdAt', true)));
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Table
        title={title}
        description={description}
        data={webhookEvents}
        columns={columns}
        options={options}
        themeProps={{ cell: { } }}
      />
    </React.Fragment>
  );
}

EventTable.propTypes = {
  username: PropTypes.string,
  viewer: PropTypes.string,
};
