import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import {
  getMileageReportingOption,
  listTripSegments,
  getCordon,
  getTod,
  getMroEventsByTripByDateTime,
} from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import TripVisualization from './components/TripVisualization';
import TripSegmentsTable from 'pages/Admin/components/TripSegmentsTable';
import TripBinsTable from 'pages/Admin/components/TripBinsTable';
import TripAdjustmentsTable from 'pages/Admin/components/TripAdjustmentsTable';
import MroEventsTable from 'pages/Admin/components/MroEventsTable';
import TripsTable from 'pages/Admin/components/TripsTable';
import VerticalTabs from 'components/Tab/VerticalTabs';
import TripLogs from './components/TripLogs';
import TripReview from './components/TripReview';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function Trip({ id: inId, computedMatch, viewer = 'admin', pilotProgram = '' }) {
  const classes = useStyles();
  const [id, setId] = useState();
  const [isNested, setIsNested] = useState(false);
  const [tabs, setTabs] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (inId) {
      setId(inId);
      setIsNested(true);
    } else if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
      setIsNested(false);
    }
  }, [inId, computedMatch]);

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);
        try {
          const [
            { data: { getTrip: trip } },
            rawSegments,
            mroEvents,
          ] = await Promise.all([
            API.graphql(graphqlOperation( /* GraphQL */ `
            query GetTrip($id: ID!) {
              getTrip(id: $id) {
                id
                recordId
                recordTripId
                mroDeviceSerialNumber
                vehicleVin
                tsStart
                tsEnd
                polyline
                distMro
                odoMro
                fuel
                distGps
                odoGps
                odoMroStart
                odoMroEnd
                fuelStart
                fuelEnd
                fuelAdded
                totalFuel
                username
                vehicleId
                pilotProgramId
                mroId
                processStatus
                processMsg
                logs
                mileageFeeCents
                fuelFeeCents
                cordonFeeCents
                todFeeCents
                tollFeeCents
                createdAt
                adjustments {
                  items {
                    tripId
                    id
                  }
                  nextToken
                }
                updatedAt
                reviewStatus
              }
            }
          `, { id })),
            asyncListAll(listTripSegments, { tripId: id }),
            asyncListAll(getMroEventsByTripByDateTime, { tripId: id }),
          ]);

          const segments = viewer === 'admin' ? rawSegments : rawSegments.filter(({ type, stateCode }) => {
            // only show public/private segments for CA
            if (type === 'state' && stateCode === 'CA') {
              return false;
            }
            // don't show public/private segments for out of state
            if ((type === 'public' || type === 'private') && stateCode !== 'CA') {
              return false;
            }
            return true;
          });

          const cordonIds = segments.reduce((ids, { cordonId }) => {
            if (cordonId && !ids.includes(cordonId)) {
              ids.push(cordonId);
            }
            return ids;
          }, []);

          const getCordonsPromises = cordonIds.map(async (cordonId) => {
            const { data: { getCordon: cordon } } = await API.graphql(graphqlOperation(getCordon, { id: cordonId }));
            return cordon;
          });

          const getTodsPromises = segments
            .filter(({ todId }) => todId)
            .map(async ({ todId }) => {
              const { data: { getTod: tod } } = await API.graphql(graphqlOperation(getTod, { id: todId }));
              return tod;
            });

          const [mroRes, cordons, tods] = await Promise.all([
            trip && trip.mroId ? API.graphql(graphqlOperation(getMileageReportingOption, { username: trip.username, id: trip.mroId })) : Promise.resolve(),
            Promise.all(getCordonsPromises),
            Promise.all(getTodsPromises),
          ]);

          const mro = mroRes ? mroRes.data.getMileageReportingOption : null;

          const tabs = [
            // RUC-296 only shows visualization for mro devices with gps enabled
            {
              label: 'Visualization',
              component: <Paper className={classes.content} elevation={2}>
                <TripVisualization
                  tripId={id}
                  polyline={trip.polyline}
                  segments={segments}
                  cordons={cordons}
                  tods={tods}
                  height={isNested ? 400 : 800}
                  viewer={viewer}
                />
              </Paper>,
              isDisabled: !mro || !mro.gpsEnabled,
            },
            {
              label: 'Segments',
              component: <TripSegmentsTable data={segments} viewer={viewer} pilotProgram={pilotProgram} />,
            },
            {
              label: 'Info',
              component: <TripsTable data={[trip]} title="Trip Details" nested={true} viewer={viewer} pilotProgram={pilotProgram} />,
              isDisabled: isNested,
            },
            {
              label: 'Bins',
              component: <TripBinsTable tripId={id} viewer={viewer} />,
              isDisabled: viewer === 'participant',
            },
            {
              label: 'Logs',
              component: <TripLogs data={(trip.logs || [])} />,
              isDisabled: viewer === 'participant',
            },
            {
              label: 'Mro Events',
              component: <MroEventsTable data={mroEvents} />,
              isDisabled: viewer === 'participant',
            },
            {
              label: 'Adjustments',
              component: <TripAdjustmentsTable tripId={id} viewer={viewer} pilotProgram={pilotProgram} />,
            },
            {
              label: 'Review',
              component: <TripReview tripId={id} />,
              isDisabled: viewer === 'participant',
            },
          ].filter(({ isDisabled }) => !isDisabled);
          setTabs(tabs);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [id, classes, isNested, viewer, pilotProgram]);

  if (!id) {
    return null;
  }

  if (isLoading || !tabs) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <VerticalTabs
      tabs={tabs}
      isNested={isNested}
    />
  );
}

Trip.propTypes = {
  id: PropTypes.string,
  computedMatch: PropTypes.object,
  viewer: PropTypes.string,
  pilotProgram: PropTypes.string,
};
