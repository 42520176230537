import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import TripAdjustmentsTable from './TripAdjustmentsTable';

function TripSegmentsTable({ title = 'Segments', description = '', data = [], viewer = 'admin', pilotProgram = '' }) {
  const [columns, setColumns] = useState([]);

  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData, rowMeta) {
      const { id, tripId } = data[rowMeta.dataIndex];
      console.log('DEBUG: data[rowMeta.dataIndex]', data[rowMeta.dataIndex]);
      return (
        <NestedTableContainer columns={columns}>
          <TripAdjustmentsTable tripId={tripId} tripSegmentId={id} viewer={viewer} pilotProgram={pilotProgram} />
        </NestedTableContainer>
      );
    },
  };
  useEffect(() => {
    const columns = [
      {
        name: 'id',
        label: 'ID',
        options: {
          display: false,
          filter: false,
          sort: false,
        },
      },
      {
        name: 'type',
        label: 'Type',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'state',
        label: 'State',
        options: {
          display: false,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'stateCode',
        label: 'State',
        options: {
          filter: true,
          sort: true,
        },
      },
      // {
      //   name: 'percentage',
      //   label: 'Percentage',
      //   options: {
      //     display: data.length <= 1 ? false : true,
      //     filter: false,
      //     sort: true,
      //     customBodyRender: (value) => {
      //       return `${(value*100).toFixed(0)}%`;
      //     },
      //   },
      // },
      {
        name: 'mileage',
        label: 'Mileage',
        type: 'mileage',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'fuel',
        label: 'Fuel',
        type: 'fuel',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'mileageFeeCents',
        label: 'Road Charge',
        type: 'currency',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'cordonFeeCents',
        label: 'Cordon Fee',
        type: 'currency',
        options: {
          display: viewer === 'participant' && pilotProgram === 'MBUF+C',
          filter: false,
          sort: true,
        },
      },
      {
        name: 'todFeeCents',
        label: 'TOD Fee',
        type: 'currency',
        options: {
          display: viewer === 'participant' && pilotProgram === 'MBUF+ToD',
          filter: false,
          sort: true,
        },
      },
      {
        name: 'tollFeeCents',
        label: 'Toll Fee',
        type: 'currency',
        options: {
          display: viewer === 'participant' && pilotProgram === 'MBUF+TF',
          filter: false,
          sort: true,
        },
      },
      {
        name: 'fuelFeeCents',
        label: 'Fuel Tax Credits',
        type: 'currency-negative',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'paymentStatus',
        label: 'Payment Status',
        options: {
          display: viewer === 'admin',
          sort: true,
        },
      },
      {
        name: 'createdAt',
        label: 'Created At',
        type: 'datetime',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'updatedAt',
        label: 'Updated At',
        type: 'datetime',
        options: {
          display: false,
          filter: false,
          sort: true,
        },
      },
    ].filter((x) => {
      return viewer === 'participant' && x.options ? x.options.display !== false : true;
    });
    setColumns(columns);
  }, [data, viewer, pilotProgram]);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
    />
  );
}

TripSegmentsTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
  viewer: PropTypes.string,
  pilotProgram: PropTypes.string,
};

export default TripSegmentsTable;
