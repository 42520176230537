import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import { updatePilotProgram } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';

import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import PilotProgram from 'pages/Admin/PilotProgram';

const title = 'Programs';
const description = '';

const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'category',
    label: 'Category',
    options: {
      filter: true,
      sort: true,
      customBodyRender(value) {
        return value ? value.toUpperCase() : '';
      },
    },
  },
  {
    name: 'name',
    label: 'Name',
    edit: {
      type: 'text',
    },
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'shortName',
    label: 'Short Name',
    edit: {
      type: 'text',
    },
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'isDeviceRequired',
    label: 'Device required',
    type: 'checkbox',
    edit: {
      type: 'checkbox',
    },
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'isGpsRequired',
    label: 'GPS required',
    type: 'checkbox',
    edit: {
      type: 'checkbox',
    },
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    edit: {
      type: 'text',
    },
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'details.participantsSelected',
    label: 'Participants Selected',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'details.assetsAndRoadScope',
    label: 'Assets and Road Scope',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'details.participantStatement',
    label: 'Participant Statement',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'details.minimumDataRequired',
    label: 'Minimum Data Required',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'details.locationGPS',
    label: 'Location/GPS',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'createdAt',
    label: 'Created At',
    type: 'datetime',
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: 'updatedAt',
    label: 'Updated At',
    type: 'datetime',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'id',
    label: ' ',
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender(id) {
        return (
          <LinkButton
            path={`/pilotProgram/${id}`}
            label="View Program details"
          />
        );
      },
    },
  },
];

const CACHE_KEY = 'PilotProgramsTable-ListPilotPrograms';

function PilotProgramsTable({ viewer = 'admin' }) {
  const [data, setData] = useState([]);
  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData, rowMeta) {
      const { id } = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <PilotProgram id={id} hideHeader={true} />
        </NestedTableContainer>
      );
    },
  };

  const onUpate = async (item, dataIndex) => {
    const input = {
      id: item.id,
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });
    await asyncRetryMutation(updatePilotProgram, { input }, { clearCacheKeys: [CACHE_KEY] });

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };


  useEffect(() => {
    (async () => {
      try {
        const records = (await asyncListAll( /* GraphQL */ `
        query ListPilotPrograms(
          $id: ID
          $filter: ModelPilotProgramFilterInput
          $limit: Int
          $nextToken: String
          $sortDirection: ModelSortDirection
        ) {
          listPilotPrograms(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
          ) {
            items {
              id
              category
              name
              shortName
              description
              isDeviceRequired
              isGpsRequired
              createdAt
              updatedAt
              details {
                participantsSelected
                assetsAndRoadScope
                participantStatement
                minimumDataRequired
                locationGPS
              }
            }
            nextToken
          }
        }
      `, undefined, { cacheKey: CACHE_KEY }));
        setData(records);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <Table
      title={title}
      description={description}
      data={data}
      columns={columns}
      options={options}
      onUpdateItem={onUpate}
    />
  );
}

PilotProgramsTable.propTypes = {
  viewer: PropTypes.string,
};

export default PilotProgramsTable;
