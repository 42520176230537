/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ParticipantMileageReports from './components/ParticipantMileageReports';
import ParticipantVehicles from './components/ParticipantVehicles';
import ParticipantTrips from './components/ParticipantTrips';
import InvalidateTrips from './components/InvalidateTrips';
import VerticalTabs from 'components/Tab/VerticalTabs';
import ParticipantStatements from './components/ParticipantStatements';
import ParticipantNotifications from './components/ParticipantNotifications';
import ParticipantMros from './components/ParticipantMros';
import MroEventsTable from 'pages/Admin/components/MroEventsTable';
import Payments from 'pages/Admin/components/Payments';
import AuditTrail from 'pages/Admin/Participant/components/AuditTrail';
import ParticipantsTable from 'pages/Admin/components/ParticipantsTable';
import MyAccount from 'pages/Participant/Account';

import { asyncGet } from 'utilities/graph';
import {
  getParticipant,
} from 'graphql/queries';

export default function Participant({
  username: inUsername,
  hideInfo = false,
  isNested = false,
  computedMatch,
}) {
  const [username, setUsername] = useState();
  const [tabs, setTabs] = useState([]);
  const [user, setUser] = useState();

  useEffect(() => {
    if (inUsername) {
      setUsername(inUsername);
    } else
    if (computedMatch) {
      const { params: { username } } = computedMatch;
      setUsername(username);
    }
  }, [inUsername, computedMatch]);

  useEffect(() => {
    if (username) {
      (async () => {
        const { data: { getParticipant: user } } = await asyncGet(getParticipant, { username });
        setUser(user);
      })();
    }
  }, [username]);

  useEffect(() => {
    if (!username || !user) return;

    const tabs = [
      !hideInfo && {
        label: 'Info',
        component: <ParticipantsTable
          title={`${user.firstName} ${user.lastName}`}
          data={[user]}
          viewer='admin'
        />,
      },
      {
        label: 'Vehicles',
        component: <ParticipantVehicles
          username={username}
          viewer='admin'
        />,
      },
      {
        label: 'MRO Devices',
        component: <ParticipantMros
          username={username}
          viewer='admin'
        />,
      },
      {
        label: 'MRO Events',
        component: <MroEventsTable
          username={username}
          viewer='admin'
        />,
      },
      {
        label: 'Trips',
        component: <ParticipantTrips
          username={username}
          viewer="admin"
        />,
      },
      {
        label: 'Mileage Reports',
        component: <ParticipantMileageReports
          username={username}
          viewer='admin'
        />,
      },
      {
        label: 'Statements',
        component: <ParticipantStatements
          username={username}
          viewer='admin'
        />,
      },
      {
        label: 'Payments',
        component: <Payments
          username={username}
          viewer='admin'
        />,
      },
      {
        label: 'Notifications',
        component: <ParticipantNotifications
          username={username}
          viewer='admin'
        />,
      },
      {
        label: 'Invalidate Trips',
        component: <InvalidateTrips
          username={username}
          viewer='admin'
        />,
      },
      {
        label: 'Audit Trail',
        component: <AuditTrail
          username={username}
          viewer='admin'
        />,
      },
      {
        label: 'Participant View',
        component: <MyAccount
          user={user}
          targetParticipant={user}
        />,
      },
    ].filter((x) => x);

    setTabs(tabs);
  }, [username, user, hideInfo]);

  if (!username || !user) {
    return null;
  }

  return (
    <Grid container direction="column">
      {computedMatch && (
        <div style={{ paddingBottom: 16 }}>
          <Typography variant="h5" component="h5" align="center">
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="body2" component="p" align="center" color="textSecondary" gutterBottom>
            {user.pilotProgram.name} ({user.pilotProgram.shortName})
          </Typography>
        </div>
      )}
      <VerticalTabs
        tabs={tabs}
        isNested={isNested}
      />
    </Grid>
  );
}

Participant.propTypes = {
  username: PropTypes.string,
  hideInfo: PropTypes.bool,
  isNested: PropTypes.bool,
  computedMatch: PropTypes.object,
  viewer: PropTypes.string,
};
