import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Logo from 'components/Logo';
import awsConfig from 'aws-exports';
import { version } from '../../../package.json';
import { APP } from 'utilities/constants';
import useScreenView from 'utilities/useScreenView';

const env = awsConfig.aws_cloud_logic_custom[0].endpoint.split('/').pop();

const useStyles = makeStyles((theme) => ({
  container: {
    'marginTop': theme.spacing(2),
    'marginBottom': theme.spacing(4),
    'color': theme.palette.text.secondary,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      display: 'flex',
      position: 'absolute',
      bottom: 0,
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { isMobileView } = useScreenView();

  const info = `v${version}` + (process.env.NODE_ENV === 'development' ? ` (${env})` : '');

  return (
    <Grid container className={classes.container} direction="row" justify="center" alignItems="center">
      <Logo avatar={true} width={isMobileView ? 10 : 'auto'} height={isMobileView ? 10 : '100%'}/>
      <div>
        © {isMobileView ? '' : 'Copyright'} {moment().format('YYYY')}
        &nbsp;
        <Link
          href="https://www.transurban.com/"
          target="_blank"
          rel="noopener"
          variant="body2"
        >
          Transurban Limited
        </Link>
        &nbsp;
        All rights reserved.
        &nbsp;
      </div>
      <div>
        <Link
          href={`${APP.LINK_PARTICIPANT_AGREEMENT}`}
          target="_blank"
          rel="noopener"
          variant="body2"
        >
          Participant Agreement
        </Link>
        &nbsp;|&nbsp;
        <Link
          href={`${APP.LINK_PRIVACY_POLICY}`}
          target="_blank"
          rel="noopener"
          variant="body2"
        >
          Privacy Policy
        </Link>
        &nbsp;|&nbsp;
        {info}
      </div>
    </Grid>
  );
}
