import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import StarIcon from '@material-ui/icons/Star';

import ControlledInput from 'components/Form/ControlledInput';

import { listPaymentCards } from 'graphql/queries';
import { storePaymentCardByToken, deletePaymentCard, updatePaymentCard } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { systemBillingFeatures } from 'utilities/constants/paymentStatus';
import Tupay from './Tupay';

import { useStyles } from './styles';

const currentYear = () => {
  const now = new Date();
  return now.getFullYear();
};

const getExpirationYears = () => {
  const year = currentYear();
  const options = [];
  for (let i = year; i < year + 10; i++) {
    options.push(i);
  }
  return options;
};

const BillingInfo = ({
  user,
  showTitle = true,
  showSaved = true,
  onCompleted = () => {
    window.location.reload();
  },
}) => {
  const [error, setError] = useState('');

  const classes = useStyles();
  const billingFeatures = systemBillingFeatures(localStorage);

  if (!billingFeatures.allowed) {
    return <Disabled classes={classes} user={user} />;
  }

  const handleCloseError = () => {
    setError('');
  };

  return (
    <Container component="main" data-test-id="billing-info-container" disableGutters>
      <Grid container className={classes.paper} justifyContent="center">
        {!showTitle ? null : (
          <>
            <Avatar variant="circle" className={classes.avatar}>
              <CreditCardOutlinedIcon color="inherit" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Billing Info
            </Typography>
          </>
        )}

        {!showSaved ? null : <Stored classes={classes} user={user} setError={setError} />}

        {
          billingFeatures.provider === 'tupay' ?
            <Tupay classes={classes} user={user} setError={setError} onCompleted={onCompleted} style={{ width: '100%' }} /> :
            <ManualEntry classes={classes} user={user} setError={setError} onCompleted={onCompleted} />
        }

        <Snackbar
          open={error != false}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Alert color="info">
            Up to three credit or debit cards can be stored for your convenience.
            Please note that saving your payment information does NOT set up autopay.
            Autopay is not allowed for this Pilot.
            You will need to pay your monthly Road Charge by logging into your account and clicking the “Pay” button on your dashboard each month.
            <br/><br/>
            If you decide to save your payment information to your account,
            you will see a $1 temporary pre-authorization charge on your credit or debit card account.
            This temporary pre-authorization charge will automatically be reversed after your account is verified.
          </Alert>
        </Grid>
      </Grid>
    </Container>
  );
};

BillingInfo.propTypes = {
  user: PropTypes.object,
  showTitle: PropTypes.bool,
  showSaved: PropTypes.bool,
  onCompleted: PropTypes.func,
};

const Stored = ({ classes, user, setError = () => {} }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const { username } = user;

  const refreshPaymentMethods = useCallback(async (username) => {
    try {
      const paymentCards = await asyncListAll(listPaymentCards, { username });
      setPaymentMethods(paymentCards || []);
    } catch (e) {
      console.warn(e);
      setError(e.message);
    }
  }, [setError]);

  useEffect(() => {
    (async () => {
      await refreshPaymentMethods(username);
    })();
  }, [username, refreshPaymentMethods]);

  if (paymentMethods.length === 0) {
    return <></>;
  }

  const handleRemoveCard = async (id) => {
    try {
      setDisabled(true);
      await asyncRetryMutation(deletePaymentCard, {
        input: {
          id,
          username,
        },
      });
    } catch (e) {
      setError(e.message);
    } finally {
      setDisabled(false);
      await refreshPaymentMethods(username);
    }
  };

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        Payment Methods
      </Typography>
      <List>
        {paymentMethods.map((method) => {
          return (
            <ListItem key={`payment-method-${method.id}`}>
              {!method.isDefault ? null : (
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
              )}
              <ListItemText
                primary={method.alias ? method.alias : `**** **** **** ${method.last4.toString().padStart(4, '0')}`}
                secondary={`Expires on ${method.expirationMonth}/${method.expirationYear}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  disabled={disabled}
                  onClick={() => handleRemoveCard(method.id)}
                >
                  <DeleteIcon color="inherit" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

Stored.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  setError: PropTypes.func,
};

const Disabled = ({ classes, user }) => {
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <CreditCardOutlinedIcon color="inherit" />
        </Avatar>
        <Typography variant="body1">
          Payment methods are currently disabled in the system and will not be used.
        </Typography>
      </div>
    </Container>
  );
};

Disabled.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
};

const ManualEntry = ({ classes, user, setError = () => {}, onCompleted = () => {} }) => {
  // form state
  const { control, errors, handleSubmit, formState, setValue } = useForm();
  const { isSubmitting } = formState;

  // billing state
  const [defaultPaymentCard] = useState({});
  const { username } = user;

  const cardInputs = [{
    type: 'text',
    name: 'cardNumber',
    defaultValue: `**** **** **** ${defaultPaymentCard.last4.toString().padStart(4, '0') || '****'}`,
    label: 'Card Number',
    required: true,
    disabled: true,
    invalidText: 'Card number is required',
  }, {
    type: 'text',
    name: 'nameOnCard',
    defaultValue: `${defaultPaymentCard.nameOnCard || ''}`,
    label: 'Name On Card',
    required: true,
    invalidText: 'Name on card is required',
  }, {
    type: 'select',
    name: 'expirationMonth',
    defaultValue: `${defaultPaymentCard.expirationMonth || 1}`,
    label: 'Exp Month',
    required: true,
    invalidText: 'Expiration month is required',
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
      return {
        value: month,
        label: month,
      };
    }),
  }, {
    type: 'select',
    name: 'expirationYear',
    defaultValue: `${defaultPaymentCard.expirationYear || currentYear()}`,
    label: 'Exp Year',
    required: true,
    invalidText: 'Expiration year is required',
    options: getExpirationYears().map((year) => {
      return {
        value: year,
        label: year,
      };
    }),
  }, {
    type: 'checkbox',
    name: 'isDefault',
    label: 'Set as default payment method',
    defaultChecked: true,
    disabled: true,
    setValue,
  }];

  async function handleSavePaymentCard({
    serviceProviderKey,
    expirationMonth,
    expirationYear,
    isDefault,
    nameOnCard,
    token,
    zipCode,
  }) {
    const now = new Date();
    const expiration = new Date(expirationYear, expirationMonth, 0);
    if (now.getTime() > expiration.getTime()) {
      setError('The card has expired');
      return;
    }

    if (defaultPaymentCard.id) {
      await asyncRetryMutation(updatePaymentCard, {
        input: {
          expirationMonth: parseInt(expirationMonth, 10),
          expirationYear: parseInt(expirationYear, 10),
          id: defaultPaymentCard.id,
          isDefault,
          nameOnCard,
          updatedBy: localStorage.getItem('ruc:username') || username || nameOnCard,
          username,
        },
      });
    } else {
      await asyncRetryMutation(storePaymentCardByToken, {
        input: {
          serviceProviderKey,
          username,
          token,
          billingZip: zipCode,
          nameOnCard,
          isDefault: true,
        },
      });
    }

    onCompleted();
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(handleSavePaymentCard)}
      noValidate
    >
      <Grid container spacing={2}>
        {cardInputs.splice(0, 2).map((input, index) => {
          return (
            <Grid item xs={12} key={index}>
              <ControlledInput
                control={control}
                errors={errors}
                {...input}
              />
            </Grid>
          );
        })}

        {cardInputs.splice(0, 2).map((input, index) => {
          return (
            <Grid item xs={6} key={index}>
              <ControlledInput
                control={control}
                errors={errors}
                {...input}
              />
            </Grid>
          );
        })}

        {cardInputs.map((input, index) => {
          return (
            <Grid item xs={12} key={index}>
              <ControlledInput
                control={control}
                errors={errors}
                {...input}
              />
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

ManualEntry.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  setError: PropTypes.func,
  onCompleted: PropTypes.func,
};

export default BillingInfo;
