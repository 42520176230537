import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useHistory,
} from 'react-router-dom';

import SelectPaymentMethod from './components/SelectPaymentMethod';
import NewPaymentMethod from './components/NewPaymentMethod';

import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import { getTransaction } from 'graphql/queries';
import { updateTransaction } from './graphql';
import { asyncGet, asyncRetryMutation } from 'utilities/graph';
import { systemBillingFeatures } from 'utilities/constants/paymentStatus';

import { useStyles } from './styles';

const Payment = ({
  user,
  computedMatch,
}) => {
  const classes = useStyles();
  const history = useHistory();

  // states
  const [error, setError] = useState(false);
  const [transactionIds, setTransactionIds] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { username } = user;

  useEffect(() => {
    if (computedMatch) {
      const { params: { transactionIds: computedTransactionIds } } = computedMatch;
      setTransactionIds(computedTransactionIds.split(';'));
    }
  }, [computedMatch]);

  useEffect(() => {
    (async () => {
      try {
        const loadedTransactions = await Promise.all(transactionIds.map((id) => {
          return asyncGet(getTransaction, { id }, { bypassCache: true });
        }));

        setTransactions(loadedTransactions.map((loadedTransaction) => {
          return loadedTransaction.data.getTransaction;
        }));
      } catch (e) {
        console.warn(e);
        setError(e.message);
      }
    })();
  }, [transactionIds]);

  // is billing enabled
  const billingFeatures = systemBillingFeatures(localStorage);
  if (!billingFeatures.allowed) {
    return history.push('/dashboard');
  }

  const handleCloseError = () => {
    setError(false);
  };

  const onPaymentSelected = async (method) => {
    setLoading(true);

    const unpaidTransactions = transactions.filter(({ status }) => status === 'created' || status === 'failed');
    if (!unpaidTransactions.length) {
      setError('There are no unpaid transactions');
      return;
    }

    try {
      await Promise.all(unpaidTransactions.map((transaction) => {
        delete transaction.participant;
        delete transaction.tripAdjustments;
        delete transaction.tripSegments;
        return asyncRetryMutation(updateTransaction, {
          input: Object.assign({ ...transaction }, {
            status: 'pending',
            paymentMethodId: method.id,
          }),
        });
      }));

      return history.push('/dashboard');
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getUnpaidBalance = () => {
    return transactions.filter(({ status }) => status === 'created' || status === 'failed').reduce((acc, next) => {
      return acc + next.amountCents;
    }, 0) / 100;
  };

  return (
    <Container component="main">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <CreditCardOutlinedIcon color="inherit" />
        </Avatar>
        <Typography component="h1" variant="h5">Make A Payment</Typography>
        <Typography component="h6" variant="h6"><strong>Balance: ${getUnpaidBalance()}</strong></Typography>
        {!loading && (
          <>
            <SelectPaymentMethod
              username={username}
              billingFeatures={billingFeatures}
              classes={classes}
              onPaymentSelected={onPaymentSelected}
            />
            <NewPaymentMethod
              username={username}
              billingFeatures={billingFeatures}
              classes={classes}
              onPaymentSelected={() => {
                window.location.reload();
              }}
            />
          </>
        )}
        <Snackbar
          open={error != false}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

Payment.propTypes = {
  user: PropTypes.object,
  computedMatch: PropTypes.object,
};

export default Payment;
