export const APP = {
  NAME_PILOT: 'California Road Charge Collection Pilot',
  SUPPORT_EMAIL: 'support@ruc-caltrans.drive-tu.com',
  SUPPORT_EMAIL_HORROCKS: 'info@caroadcharge.com',
  FAQ_LINK: 'https://caroadcharge.com/media/15aparun/ca_sb_339_faq_remediated.pdf',
  LINK_PARTICIPANT_AGREEMENT: '/documents/CA-SB339_Participant-Agreement_5-16-2024_a11y.pdf',
  LINK_PRIVACY_POLICY: '/documents/CA-SB339_Privacy-Policy_6-07-2024_a11y.pdf',
  PHONE_DIGITS: '+19166196283',
  PHONE_FORMAT: '+1 (916) 619-6283',
};
