import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { storePaymentCardByToken } from 'graphql/mutations';
import { asyncRetryMutation, asyncListAll, asyncGet } from 'utilities/graph';

import Typography from '@material-ui/core/Typography';

import { listPaymentCards, getPaymentCard } from 'graphql/queries';

const NewPaymentMethod = ({
  username,
  classes,
  onPaymentSelected,
  setError,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(600);

  useEffect(() => {
    if (loaded) {
      return;
    }

    // Expected structure
    // res: {
    //   token: "",
    //   serviceProviderKey: "",
    //   card: {
    //     cardExpMonth: "01",
    //     cardExpYear: "2024",
    //     cardLastFour: "1111",
    //     cardType: "Visa",
    //     cardHolderEmail: "charles.f.xavier@xmen.org",
    //   },
    //   transaction: {
    //     amount: 100,
    //     customOrderId: "custom_order_id",
    //     customTransactionId: "custom_transaction_id",
    //     status: "SUCCESS",
    //     traceTransactionId: "3tF6UTQFdB8fA7w1sfkkiS",
    //     verificationCode: "SFKKIS",
    //   },
    // }
    const handleTokenGenerate = (res) => {
      (async () => {
        console.log('onTokenGenerate', res);

        // get the payment cards
        let paymentMethods;
        try {
          paymentMethods = await asyncListAll(listPaymentCards, { username });
          console.log('paymentMethods', paymentMethods);
        } catch (e) {
          console.warn(e);
          // setError(e.message);
        }

        if (paymentMethods && paymentMethods.length >= 3) {
          // setError('A maximum of 3 cards are allowed. Please delete some before adding more.');
          alert('A maximum of 3 cards are allowed. Please delete some before adding more.');
        } else {
          try {
            const {
              data: {
                storePaymentCardByToken: {
                  paymentMethodId,
                },
              },
            } = await asyncRetryMutation(storePaymentCardByToken, {
              input: {
                serviceProviderKey: res.serviceProviderKey,
                isDefault: true,
                nameOnCard: res.card.cardHolderName,
                username,
                token: res.token,
              },
            });

            const {
              data: {
                getPaymentCard: paymentMethod,
              },
            } = await asyncGet(getPaymentCard, {
              username,
              id: paymentMethodId,
            });

            onPaymentSelected(paymentMethod);
          } catch (e) {
            setError(e.message);
          }
        }
      })();
    };

    try {
      const TUPAY_IFRAME_PRODUCT_KEY = localStorage.getItem('ruc:configuration:TUPAY_IFRAME_PRODUCT_KEY');
      const TUPAY_IFRAME_URL = localStorage.getItem('ruc:configuration:TUPAY_IFRAME_URL');

      const pay = new window.Pay(TUPAY_IFRAME_PRODUCT_KEY);
      pay.createPaymentElement('#pay-tupay', {
        url: TUPAY_IFRAME_URL,
        paymentMethodType: 'token',
        serviceProviderShortCodes: [],
        onTokenGenerate: handleTokenGenerate,
        onComplete: (res) => {
          console.log('onComplete', res);
        },
        onError: (err) => {
          console.log('onError', err);
        },
        onMount: (info) => {
          if (info.height) {
            setHeight(info.height + 60);
          }
        },
        appearance: {
          paymentCopy: 'Please provide your payment details.',
          submitButtonText: 'Add payment method',
          theme: '.MuiButton-contained { color: #fff !important; background-color: rgb(11, 20, 56) !important; } ' +
            '.MuiButton-outlined { color: rgb(11, 20, 56) !important; border: 1px solid rgba(11, 20, 56, 0.5) !important; } ' +
            '.MuiButton-outlined:hover { background-color: rgba(11, 20, 56, 0.04) !important; } ' +
            '.Mui-focused { color: rgb(11, 20, 56) !important; } ' +
            '.Mui-focused fieldset { border: 1px solid rgb(11, 20, 56) !important; }',
        },
        customer: {
          cardHolderName: '',
          cardHolderPhone: 'N/A',
          cardHolderEmail: 'email@email.com',
          billingAddress1: 'N/A',
        },
      });
    } catch (e) {
      console.log('pay exception', e);
    } finally {
      setLoaded(true);
    }
  }, [loaded, setError, username, onPaymentSelected]);

  return (
    <div className={classes.paper}>
      <hr className={classes.divider} />
      <Typography component="h1" variant="h5">
        Register a new payment method for payment
      </Typography>
      <div id="pay-tupay" className={classes.tupay} style={{ width: '100%', height }}></div>
    </div>
  );
};

NewPaymentMethod.propTypes = {
  username: PropTypes.string,
  classes: PropTypes.object,
  onPaymentSelected: PropTypes.func,
  setError: PropTypes.func,
};

export default NewPaymentMethod;
