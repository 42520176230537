import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';

import {
  listParticipants,
  listParticipantStatements,
  getEventsByEventNameByTimestamp,
} from './graphql';
import {
  listMROEvents,
  listPaymentCards,
} from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const overdueDays = localStorage.getItem('ruc:configuration:BILLING_BALANCE_OVERDUE_DAYS');

function mapFields(participants, cards, updateEvents, insertEvents, deletedEvents, mroEvents, statements) {
  const participantUpdateEvents = updateEvents.filter(({ key }) => key.match(/^Participant/));
  const participantInsertEvents = insertEvents.filter(({ key }) => key.match(/^Participant/));
  const vehicleInsertEvents = insertEvents.filter(({ key }) => key.match(/^Vehicle/));
  const vehicleDeletedEvents = deletedEvents.filter(({ key }) => key.match(/^Vehicle/));
  const uniqueCards = new Set(cards.map(({ username }) => username));

  const onboardedEvents = participantUpdateEvents.filter(({ diff }) => {
    return diff.find(({ key, new: newValue }) => key === 'status' && newValue === '"onboarded"');
  });
  const approvedEvents = participantUpdateEvents.filter(({ diff }) => {
    return diff.find(({ key, new: newValue }) => key === 'status' && newValue === '"approved"');
  });
  const activeEvents = participantUpdateEvents.filter(({ diff }) => {
    return diff.find(({ key, new: newValue }) => key === 'status' && newValue === '"active"');
  });
  const flaggedEvents = participantUpdateEvents.filter(({ diff }) => {
    return diff.find(({ key, new: newValue }) => key === 'status' && newValue === '"flagged"');
  });
  const suspendedEvents = participantUpdateEvents.filter(({ diff }) => {
    return diff.find(({ key, new: newValue }) => key === 'status' && newValue === '"suspended"');
  });
  const closedEvents = participantUpdateEvents.filter(({ diff }) => {
    return diff.find(({ key, new: newValue }) => key === 'status' && newValue === '"closed"');
  });
  const nonPayEvents = participantUpdateEvents.filter(({ diff }) => {
    return diff.find(({ key, new: newValue }) => key === 'flags' && /"isBillingOverdue":true/.test(newValue));
  });
  const suspendedHeartBeatEvents = participantUpdateEvents.filter(({ diff }) => {
    return diff.find(({ key, new: newValue }) => key === 'flags' && /"isInactive":true/.test(newValue));
  });
  const suspendedVinMismatchEvents = participantUpdateEvents.filter(({ diff }) => {
    return diff.find(({ key, new: newValue }) => key === 'flags' && /"isVinMismatch":true/.test(newValue));
  });
  const heartbeatEvents = mroEvents.filter(({ description }) => /^OBDII device has not reported data for a 72-hour period/.test(description));
  const mismatchedEvents =
    mroEvents.filter(({ description }) => /^Failed to match trip vin to the lookup vehicle vin \(by MRO serial\)/.test(description));

  const statementsPaidByDueDate = statements.reduce((acc, { createdAt, paidAt }) => {
    const daysToPay = paidAt ? moment(createdAt).diff(moment(paidAt), 'days') : overdueDays;
    return acc + (daysToPay < overdueDays ? 1 : 0);
  }, 0);

  return {
    'Total Created Accounts': participantInsertEvents.length,
    'Total Onboarded Accounts': onboardedEvents.length,
    'Total Approved Accounts': approvedEvents.length,
    'Total Active Accounts': activeEvents.length,
    'Total Flagged Accounts': flaggedEvents.length,
    'Total Non-Pay Flagged Accounts': nonPayEvents.length,
    'Total Compliant (no-Issue) Accounts': activeEvents.length - flaggedEvents.length,
    'Total Suspended Accounts': suspendedEvents.length,
    'Total Closed Accounts': closedEvents.length,
    'Total Active VINs': vehicleInsertEvents.length - vehicleDeletedEvents.length,
    'Total Flagged-No Heartbeat VINs': heartbeatEvents.length,
    'Total Flagged-VIN Mismatch VINs': mismatchedEvents.length,
    'Total Suspended-No Heartbeat VINs': suspendedHeartBeatEvents.length,
    'Total Suspended-VIN Mismatch VINs': suspendedVinMismatchEvents.length,
    'Total Deleted VINs': vehicleDeletedEvents.length,
    'Total Accounts with Stored Payment Method': uniqueCards.size,
    'Total Accounts without Stored Payment Method': participants.length - uniqueCards.size,
    'Total Account PAID Statements by Statement Due Date': statementsPaidByDueDate,
    'Total Account NOT PAID Statements by Statement Due Date': statements.length - statementsPaidByDueDate,
  };
}

export default function SummaryReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      const [participants, cards, updateEvents, insertEvents, deletedEvents, mroEvents, statements] = await Promise.all([
        asyncListAll(listParticipants, { filter: { onboardedDate: { between: [from, to] } } }),
        asyncListAll(listPaymentCards, { filter: { createdAt: { between: [from, to] } } }),
        asyncListAll(getEventsByEventNameByTimestamp,
          { eventName: 'MODIFY', timestamp: { lt: to } }),
        asyncListAll(getEventsByEventNameByTimestamp,
          { eventName: 'INSERT', timestamp: { lt: to } }),
        asyncListAll(getEventsByEventNameByTimestamp,
          { eventName: 'REMOVE', timestamp: { lt: to } }),
        asyncListAll(listMROEvents, { filter: { dateTime: { between: [from, to] } } }),
        asyncListAll(listParticipantStatements, { filter: { periodFrom: { between: [from, to] } } }),
      ]);

      return [mapFields(participants, cards, updateEvents, insertEvents, deletedEvents, mroEvents, statements)];
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Summary Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Summary_Report.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
