import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';
import {
  listParticipantInquirysWithCommentsParticpantsVehicles,
  getEventsByUserByTimestamp,
} from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function mapFields(inquiry, participant, vehicle, approvedEvent, vehicleEvent) {
  const id = `${participant.accountNo}-${vehicle.vin}`;
  const comments = inquiry.comments.items.sort((a, b) => {
    return moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1;
  });

  const firstComment = comments[0];

  let closureComment = '';
  if (inquiry.status === 'closed') {
    const commentsReversed = inquiry.comments.items.sort((a, b) => {
      return moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1;
    });
    const lastAdminComment = commentsReversed.find((comment) => comment.author === 'admin');
    closureComment = lastAdminComment == undefined ? '' : lastAdminComment.body;
  }

  return {
    'RecordID': id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'Address Line 1': participant.address.address1,
    'Address Line 2': participant.address.address2,
    'City': participant.address.city,
    'State': participant.address.state,
    'Zip Code': participant.address.postalCode + (participant.address.extendedPostalCode || ''),
    'Email Address': participant.email,
    'Telephone Number': participant.phoneNumber,
    'RUC Group': participant.pilotProgram.shortName === 'MBUF+DR' ? 'Variable Rate' : 'Flat Rate',
    'VIP': participant.flags.isVIP || false,
    'Account Status': participant.status,
    'Account Created Date': participant.createAt,
    'Account Onboarded Date': participant.onboardedDate,
    'Account Approved Date': approvedEvent == undefined ? '' : approvedEvent.createdAt,
    'Account Active Date': participant.firstDataReceivedDate,
    'VIN': vehicle.vin,
    'Vehicle Make': vehicle.make,
    'Vehicle Model': vehicle.model,
    'Vehicle Year': vehicle.year,
    'Vehicle Fuel Type': vehicle.type,
    'Vehicle Fuel Economy': vehicle.epaVehicleCombinedMpg,
    'VIN Added Date': vehicle.createdAt,
    'VIN Delete Date': vehicleEvent == undefined ? '' : vehicleEvent.createdAt,
    'MRO ID': vehicle.mro ? vehicle.mro.deviceSerialNumber : '',
    'MRO Type': vehicle.mro ? vehicle.mro.mroDevicePreference : '',
    'MRO Ship Date': vehicle.mro ? vehicle.mro.shippedDate : '',
    'Issue Original ID': firstComment.id,
    'Issue ID': inquiry.id,
    'Issue Creator ID': inquiry.username || '',
    'Issue Created Date': inquiry.createdAt,
    'Issue Updater ID': inquiry.updatedBy || '',
    'Issue Initiation Mode': inquiry.emailOriginalMessageId != undefined || inquiry.emailS3Key != undefined ? 'email' : 'UI',
    'Issue Type': inquiry.category,
    'Issue Subject': inquiry.subject,
    'Issue Description': inquiry.body,
    'Issue Status': inquiry.status,
    'Issue Resolution Comments': closureComment,
  };
}

function mapNoUserFields(inquiry) {
  const comments = inquiry.comments.items.sort((a, b) => {
    return moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1;
  });

  const firstComment = comments[0];

  let closureComment = '';
  if (inquiry.status === 'closed') {
    const commentsReversed = inquiry.comments.items.sort((a, b) => {
      return moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1;
    });
    const lastAdminComment = commentsReversed.find((comment) => comment.author === 'admin');
    closureComment = lastAdminComment == undefined ? '' : lastAdminComment.body;
  }

  return {
    'RecordID': '',
    'AccountID': '',
    'Last Name': '',
    'First Name': '',
    'Address Line 1': '',
    'Address Line 2': '',
    'City': '',
    'State': '',
    'Zip Code': '',
    'Email Address': '',
    'Telephone Number': '',
    'RUC Group': '',
    'VIP': '',
    'Account Status': '',
    'Account Created Date': '',
    'Account Onboarded Date': '',
    'Account Approved Date': '',
    'Account Active Date': '',
    'VIN': '',
    'Vehicle Make': '',
    'Vehicle Model': '',
    'Vehicle Year': '',
    'Vehicle Fuel Type': '',
    'Vehicle Fuel Economy': '',
    'VIN Added Date': '',
    'VIN Delete Date': '',
    'MRO ID': '',
    'MRO Type': '',
    'MRO Ship Date': '',
    'Issue Original ID': firstComment.id,
    'Issue ID': inquiry.id,
    'Issue Creator ID': inquiry.username || '',
    'Issue Created Date': inquiry.createdAt,
    'Issue Updater ID': inquiry.updatedBy || '',
    'Issue Initiation Mode': inquiry.emailOriginalMessageId != undefined || inquiry.emailS3Key != undefined ? 'email' : 'UI',
    'Issue Type': inquiry.category,
    'Issue Subject': inquiry.subject,
    'Issue Description': inquiry.body,
    'Issue Status': inquiry.status,
    'Issue Resolution Comments': closureComment,
  };
}

export default function CustomerInteractionReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      const inquires = await asyncListAll(listParticipantInquirysWithCommentsParticpantsVehicles, { filter: { createdAt: { between: [from, to] } } });
      const data = await Promise.all(inquires.map(async (inquiry) => {
        if (inquiry.participant) {
          const participant = inquiry.participant;
          // get approved date from events
          const eventTo = moment.utc().format();
          const events = await asyncListAll(getEventsByUserByTimestamp, { username: participant.username, timestamp:
            { between: [participant.createdAt, eventTo] }, filter: { eventName: { eq: 'MODIFY' }, or: { eventName: { eq: 'REMOVE' } } } });
          const participantEventsModified = events.filter(({ key, eventName }) => key.match(/^Participant/) && eventName === 'MODIFY');
          const approvedEvent = participantEventsModified.find(({ diff }) => {
            return diff.find(({ key, new: newValue }) => key === 'status' && newValue === '"approved"');
          });
          const vehicleEventsRemoved = events.filter(({ key, eventName }) => key.match(/^Vehicle/) && eventName === 'REMOVE');

          return participant.vehicles.items.map((vehicle) => {
            const vehicleEvent = vehicleEventsRemoved.find(({ diff }) => {
              return diff.find(({ key, old }) => key === 'id' && old === `"${vehicle.id}"`);
            });

            return mapFields(inquiry, participant, vehicle, approvedEvent, vehicleEvent);
          });
        } else {
          return mapNoUserFields(inquiry);
        }
      }));

      return data.flat();
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Customer Interaction Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Export_Customer_Interactions.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
