import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { listPaymentCards } from 'graphql/queries';
import { storePaymentCardByToken } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import useScreenView from 'utilities/useScreenView';

const Tupay = ({ classes, user, setError = () => {}, onCompleted = () => {} }) => {
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(600);

  const { size } = useScreenView();
  const { username } = user;

  useEffect(() => {
    if (loaded || !size) {
      return;
    }

    const handleSavePaymentCard = async ({
      serviceProviderKey,
      expirationMonth,
      expirationYear,
      isDefault,
      nameOnCard,
      token,
      zipCode,
    }) => {
      const now = new Date();
      const expiration = new Date(expirationYear, expirationMonth, 0);

      // get the payment cards
      let paymentMethods;
      try {
        paymentMethods = await asyncListAll(listPaymentCards, { username });
        console.log('paymentMethods', paymentMethods);
      } catch (e) {
        console.warn(e);
        setError(e.message);
      }

      if (now.getTime() > expiration.getTime()) {
        setError('The card has expired');
        alert('The card has expired');
      } else if (paymentMethods && paymentMethods.length >= 3) {
        setError('A maximum of 3 cards are allowed. Please delete some before adding more.');
        alert('A maximum of 3 cards are allowed. Please delete some before adding more.');
      } else {
        await asyncRetryMutation(storePaymentCardByToken, {
          input: {
            serviceProviderKey,
            username,
            token,
            billingZip: zipCode,
            nameOnCard,
            isDefault,
          },
        });
      }
    };

    const handleTokenGenerate = (res) => {
      // Expected structure
      // res: {
      //   token: "",
      //   serviceProviderKey: "",
      //   card: {
      //     cardExpMonth: "01",
      //     cardExpYear: "2024",
      //     cardLastFour: "1111",
      //     cardType: "Visa",
      //     cardHolderEmail: "charles.f.xavier@xmen.org",
      //   },
      //   transaction: {
      //     amount: 100,
      //     customOrderId: "custom_order_id",
      //     customTransactionId: "custom_transaction_id",
      //     status: "SUCCESS",
      //     traceTransactionId: "3tF6UTQFdB8fA7w1sfkkiS",
      //     verificationCode: "SFKKIS",
      //   },
      // }
      (async () => {
        try {
          await handleSavePaymentCard({
            serviceProviderKey: res.serviceProviderKey,
            expirationMonth: res.card.cardExpMonth,
            expirationYear: res.card.cardExpYear,
            isDefault: true,
            nameOnCard: res.card.cardHolderName,
            token: res.token,
            zipCode: res.card.cardHolderZipCode,
          });
          onCompleted();
        } catch (e) {
          console.warn(e);
          setError(e.message);
        }
      })();
    };

    const TUPAY_IFRAME_PRODUCT_KEY = localStorage.getItem('ruc:configuration:TUPAY_IFRAME_PRODUCT_KEY');
    const TUPAY_IFRAME_URL = localStorage.getItem('ruc:configuration:TUPAY_IFRAME_URL');

    const pay = new window.Pay(TUPAY_IFRAME_PRODUCT_KEY);
    const payConfig = {
      url: TUPAY_IFRAME_URL,
      paymentMethodType: 'token',
      serviceProviderShortCodes: [],
      onTokenGenerate: handleTokenGenerate,
      onError: (err) => {
        console.error('onError:', err);
      },
      onMount: (info) => {
        if (info.height) {
          setHeight(info.height + 60);
        }
      },
      customer: {
        cardHolderName: '',
        cardHolderPhone: 'N/A',
        cardHolderEmail: 'email@email.com',
        billingAddress1: 'N/A',
      },
      appearance: {
        paymentCopy: 'Please provide your payment details.',
        submitButtonText: 'Add payment method',
        theme: `
          .MuiButton-contained {
            color: #fff !important;
            background-color: rgb(11, 20, 56) !important;
          } 
          .MuiButton-contained.Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.12) !important;
          }
          .MuiButton-contained.Mui-disabled {
            cursor: default;
            pointer-events: none;
          }
          .MuiButton-outlined {
            color: rgb(11, 20, 56) !important;
            border: 1px solid rgba(11, 20, 56, 0.5) !important;
          }
          .MuiButton-outlined:hover {
            background-color: rgba(11, 20, 56, 0.04) !important;
          }
          .Mui-focused {
            color: rgb(11, 20, 56) !important;
          }
          .Mui-focused fieldset {
            border: 1px solid rgb(11, 20, 56) !important;
          }
        `,
        maxWidth: size,
        disableGutter: true,
      },
    };
    pay.createPaymentElement('#pay-tupay', payConfig);

    setLoaded(true);
  }, [loaded, setError, username, onCompleted, size]);

  return (
    <Grid container spacing={0} disableGutter data-test-id="billing-info-container">
      <Grid item xs={12}>
        <div id="pay-tupay" className={classes.tupay} style={{ width: '100%', height }}></div>
      </Grid>
    </Grid>
  );
};

Tupay.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  setError: PropTypes.func,
  onCompleted: PropTypes.func,
};

export default Tupay;
