import React from 'react';
import PropTypes from 'prop-types';
import {
  Link as RouteLink,
} from 'react-router-dom';

import Button from '@material-ui/core/Button';
import PaymentIcon from '@material-ui/icons/Payment';
import Typography from '@material-ui/core/Typography';

const PaymentButton = ({
  balance,
  transactions,
  text = '',
  showText = true,
}) => {
  if (!text || text === '') {
    text = `Pay ${balance}`;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      aria-label="pay"
      component={RouteLink}
      to={`/payment/${transactions.map(({ id }) => id).join(';')}`}
      title={text}
      endIcon={<PaymentIcon />}
    >
      {showText && (<Typography component="span">{text}</Typography>)}
    </Button>
  );
};

PaymentButton.propTypes = {
  transactions: PropTypes.array,
  balance: PropTypes.string,
  text: PropTypes.string,
  showText: PropTypes.bool,
};

export default PaymentButton;
