import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  createTripAdjustment,
  updateTrip,
} from 'graphql/mutations';
import {
  asyncRetryMutation,
} from 'utilities/graph';


const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));


export default function InvalidateTripsSelector({
  data,
  disabled = false,
  onSubmit,
}) {
  const classes = useStyles();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [note, setNote] = useState('');

  const submit = async () => {
    try {
      setIsSubmitting(true);

      const adjustmentPromises = data
        .filter((trip) => (trip.invalid == undefined || !trip.invalid))
        .flatMap((trip) => trip.tripSegments.items)
        .map(async (tripSegment) => {
          console.log('tripSegment', tripSegment);
          const input = {
            tripId: tripSegment.tripId,
            username: tripSegment.username,
            tripSegmentId: tripSegment.id,
            tripSegmentDate: tripSegment.createdAt,
            vehicleId: tripSegment.vehicleId,
            type: tripSegment.type,
            state: tripSegment.state,
            stateCode: tripSegment.stateCode,
            cordonId: tripSegment.cordonId,
            cordonName: tripSegment.cordonName,
            todId: tripSegment.todId,
            tollId: tripSegment.tollId,
            adjMileage: tripSegment.mileage == 0 ? tripSegment.mileage : -tripSegment.mileage,
            adjMileageFeeCents: tripSegment.mileageFeeCents == 0 ? tripSegment.mileageFeeCents : -tripSegment.mileageFeeCents,
            adjFuel: tripSegment.fuel == 0 ? tripSegment.fuel : -tripSegment.fuel,
            adjFuelFeeCents: tripSegment.fuelFeeCents == 0 ? tripSegment.fuelFeeCents : -tripSegment.fuelFeeCents,
            adjCordonFeeCents: tripSegment.cordonFeeCents == 0 ? tripSegment.cordonFeeCents : -tripSegment.cordonFeeCents,
            adjTodFeeCents: tripSegment.todFeeCents == 0 ? tripSegment.todFeeCents : -tripSegment.todFeeCents,
            adjTollFeeCents: tripSegment.tollFeeCents == 0 ? tripSegment.tollFeeCents : -tripSegment.tollFeeCents,
            note,
            createdBy: localStorage.getItem('ruc:username'),
            createdAt: new Date().toISOString(),
            paymentStatus: 'pending',
          };

          const { data: { createTripAdjustment: savedItem } } = await asyncRetryMutation(createTripAdjustment, {
            input,
          });

          return savedItem;
        });
      const savedItems = await Promise.all(adjustmentPromises);

      const tripPromises = savedItems
        .filter((item) => Object.hasOwn(item, 'tripId'))
        .map(async (adjustment) => {
          const input = {
            id: adjustment.tripId,
            invalid: true,
          };

          const { data: { updateTrip: updatedItem } } = await asyncRetryMutation(updateTrip, {
            input,
          });

          return updatedItem;
        });
      await Promise.all(tripPromises);

      await onSubmit();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            <TextField
              id={'note'}
              name={'note'}
              label={'Note'}
              type={'text'}
              fullWidth
              disabled={disabled || isSubmitting}
              variant="outlined"
              multiline
              required={true}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setSubmitDisabled(false);
                  setNote(e.target.value);
                } else {
                  setSubmitDisabled(true);
                }
              }}
            />
          </Grid>
          <Grid container item xs={12} md={2} alignItems="center" justify="left">
            <Button
              variant="contained"
              disabled={submitDisabled || isSubmitting}
              onClick={submit}
            >
              Invalidate
            </Button>
            {(disabled || isSubmitting) && <CircularProgress color="primary" size={25} style={{ marginLeft: 16 }} />}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

InvalidateTripsSelector.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};
