import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';
import { convertFuel, convertMileage } from 'utilities/format';

import {
  listParticipantsWithVehicles,
  getParticipantStatementsByUsernameByCreatedAt,
} from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function mapFields(participant, vehicle, vehicleSummaries, adjustmentSummaries, tripSummaries) {
  const id = `${participant.accountNo}-${vehicle.vin}`;
  let mroCertId = '';
  switch (participant.mroDevicePreference) {
    case 'automatedWithLocation':
      mroCertId = '5';
      break;
    case 'automatedWithoutLocation':
      mroCertId = '4';
      break;
  }

  const totalMileage = vehicleSummaries.reduce((acc, { mileage }) => acc + convertMileage(mileage, 'mi'), 0);
  const totalChargeableMileage = tripSummaries.filter(({ stateCode }) => stateCode === 'CA')
    .reduce((acc, { publicMileage }) => acc + convertMileage(publicMileage, 'mi'), 0);
  const totalNonChargableMileage = totalMileage - totalChargeableMileage;

  return {
    'RecordID': id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'RUC Group': participant.pilotProgram == undefined ? '' : participant.pilotProgram.shortName === 'MBUF+DR' ? 'Variable Rate' : 'Flat Rate',
    'VIP': participant.flags == undefined ? false : participant.flags.isVIP || false,
    'VIN': vehicle.vin,
    'Vehicle Registration State': vehicle.registrationState,
    'Vehicle Fuel Type': vehicle.type,
    'Vehicle Fuel Economy': vehicle.epaVehicleCombinedMpg,
    'MRO ID': vehicle.mro ? vehicle.mro.deviceSerialNumber : '',
    'MRO Type': vehicle.mro ? vehicle.mro.mroDevicePreference : '',
    'MRO Cert ID': mroCertId,
    'PTD_Total Mileage': totalMileage,
    'Total Chargeable Mileage': totalChargeableMileage,
    'Total Non-Chargeable Mileage': totalNonChargableMileage,
    'Total Non-Chargeable Mileage_CA': tripSummaries.filter(({ stateCode }) => stateCode === 'CA')
      .reduce((acc, { privateMileage }) => acc + convertMileage(privateMileage, 'mi'), 0),
    'PTD_Total RUC': vehicleSummaries.reduce((acc, { mileageFee }) => acc + mileageFee, 0),
    'PTD_Total Fuel Usage': vehicleSummaries.reduce((acc, { fuel }) => acc + convertFuel(fuel, 'gal'), 0),
    'PTD_Total Fuel Tax Credit': vehicle.type === 'electric' ? '' : vehicleSummaries.reduce((acc, { fuelFee }) => acc + Math.abs(fuelFee), 0),
    'PTD_Total RIF Credit': vehicle.type !== 'electric' ? '' : vehicleSummaries.reduce((acc, { fuelFee }) => acc + Math.abs(fuelFee), 0),
    'PTD_Net RUC Revenue': vehicleSummaries.reduce((acc, { taxDifference }) => acc + taxDifference, 0),
    'Adjustment_Total Mileage': adjustmentSummaries.reduce((acc, { adjMileage }) => acc + convertMileage(adjMileage, 'mi'), 0),
    'Adjustment_Total RUC': adjustmentSummaries.reduce((acc, { adjMileageFee }) => acc + adjMileageFee, 0),
    'Adjustment_Total Fuel Usage': adjustmentSummaries.reduce((acc, { adjFuel }) => acc + convertFuel(adjFuel, 'gal'), 0),
    'Adjustment _Total Fuel Tax Credit': adjustmentSummaries.reduce((acc, { adjFuelFee }) => acc + Math.abs(adjFuelFee), 0),
    'Adjustment_Total Net RUC Revenue': adjustmentSummaries.reduce((acc, { adjMileageFee, adjFuelFee }) => acc + (adjMileageFee - Math.abs(adjFuelFee)), 0),
  };
}

export default function BillingMonthReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      const participants = await asyncListAll(listParticipantsWithVehicles, { filter: { onboardedDate: { between: [from, to] } } });
      const data = await Promise.all(participants.map(async (participant) => {
        const statements = await asyncListAll(getParticipantStatementsByUsernameByCreatedAt, {
          username: participant.username,
          filter: {
            createdBy: { eq: 'System' },
          },
        });

        const emptySummary = {
          vehicleId: '',
          fuel: 0,
          fuelFee: 0,
          mileage: 0,
          mileageFee: 0,
          taxDifference: 0,
        };
        const emptyAdjustment = {
          vehicleId: '',
          adjMileage: 0,
          adjMileageFee: 0,
          adjFuel: 0,
          adjFuelFee: 0,
        };
        const emptyTripSummary = {
          vehicleId: '',
          privateFuelFee: 0,
          privateFuels: 0,
          privateMileage: 0,
          privateMileageFee: 0,
          privateTaxDifference: 0,
          publicFuelFee: 0,
          publicFuels: 0,
          publicMileage: 0,
          publicMileageFee: 0,
          publicTaxDifference: 0,
          stateCode: '',
        };

        return participant.vehicles.items.map((vehicle) => {
          const vehicleSummaries = statements.map((statement) => {
            const summary = statement.vehiclesSummary || [];
            return Object.assign({}, emptySummary, summary.find(({ vehicleId }) => vehicleId === vehicle.id));
          });
          const adjustmentSummaries = statements.map((statement) => {
            const adjustment = statement.adjustmentsSummary || [];
            return Object.assign({}, emptyAdjustment, adjustment.find(({ vehicleId }) => vehicleId === vehicle.id));
          });
          const tripSummaries = statements.flatMap((statement) => {
            const trips = statement.tripsSummary == undefined ? [] : statement.tripsSummary.filter(({ vehicleId }) => vehicleId === vehicle.id);
            if (trips.length > 0) {
              return trips;
            } else {
              return emptyTripSummary;
            }
          });
          return mapFields(participant, vehicle, vehicleSummaries, adjustmentSummaries, tripSummaries);
        });
      }));

      return data.flat();
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Billing Customer Totals Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Export_Billing_Totals.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
