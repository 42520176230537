import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { Storage } from 'aws-amplify';

import Table from 'components/Table';
import LinkButton from 'components/Table/LinkButton';
import { listParticipantStatements } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import CreateStatementDialog from './CreateStatementDialog';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import StatementDetails from './StatementDetails';

const title = 'Statements';
const description = '';

function StatementsTable({ data: inData, username, viewer = 'admin', nested = false }) {
  const [data, setData] = useState([]);
  const [participants, setParticipants] = useState();
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const options = {
    download: viewer === 'admin',
    filter: viewer === 'admin',
    expandableRows: true,
    isRowExpandable: () => nested ? false : true,
    renderExpandableRow(_rowData, rowMeta) {
      const statement = data[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={columns}>
          <StatementDetails
            statement={statement}
            viewer={viewer}
            nested={true}
          />
        </NestedTableContainer>
      );
    },
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: 'Username',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'participantName',
      label: 'Participant',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'month',
      label: 'Month',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'periodFrom',
      label: 'From',
      type: 'datetime-pst',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'periodTo',
      label: 'To',
      type: 'datetime-pst',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'trips',
      label: 'Trips',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mileage',
      label: 'Mileage',
      type: 'number', // already converted in the statement process
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'totalNewMileageFee',
      label: 'Mileage Fee',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fuel',
      label: 'Fuel',
      type: 'number', // already converted in the statement process
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fuelTaxCredit',
      label: 'Fuel Tax Credits',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'totalNewAdjCharges',
      label: 'Adjustments',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'carryoverBalance',
      label: 'Carryover Balance',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'balance',
      label: 'Balance',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'note',
      label: 'Note',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'shouldSendEmailToParticipant',
      label: 'Emailed',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'paidAt',
      label: 'Paid At',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'username',
      label: 'Participant',
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender(username) {
          return (
            <LinkButton
              path={`/participant/${username}?tab=Statements`}
              label="View participant details"
            />
          );
        },
      },
    },
    {
      name: 'filename',
      label: 'File',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite(dataIndex) {
          if (!data[dataIndex]) return null;

          const { username, filename, isProcessed, balance } = data[dataIndex];

          if (!isProcessed || balance === null) return null;

          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                const key = `participant/${username}/statements/${filename}`;
                const url = await Storage.get(key, { level: 'public' });
                console.log('Statements URL:', url);
                window.open(url);
              }}
            >
              PDF
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const participants = await asyncListAll( /* GraphQL */ `
          query ListParticipants(
            $username: String
            $filter: ModelParticipantFilterInput
            $limit: Int
            $nextToken: String
            $sortDirection: ModelSortDirection
          ) {
            listParticipants(
              username: $username
              filter: $filter
              limit: $limit
              nextToken: $nextToken
              sortDirection: $sortDirection
            ) {
              items {
                username
                firstName
                lastName
                email
              }
              nextToken
            }
          }
        `, undefined, { bypassCache: false });
      setParticipants(participants);
    })();
  }, []);

  useEffect(() => {
    if (!participants) return;
    (async () => {
      try {
        const records = inData ? inData : (await asyncListAll(listParticipantStatements, undefined, { bypassCache: true }));
        setData(records
          .sort(sortBy('createdAt', true))
          .map((item) => {
            const participant = participants.find(({ username }) => username === item.username);
            const admin = participants.find(({ username }) => username === item.createdBy);
            item.participantName = participant ? `${participant.firstName} ${participant.lastName}` : '';
            item.adminName = admin ? `Admin (${admin.email})` : item.createdBy;

            return item;
          }));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [inData, participants, lastUpdatedAt]);


  if (!participants) return null;

  return (
    <React.Fragment>
      <Table
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={options}
        onHandleCreateItem={() => setCreateDialogOpen(true)}
      />
      <CreateStatementDialog
        username={username}
        isOpen={createDialogOpen}
        onUpdate={() => {
          setLastUpdatedAt(Date.now());
          setCreateDialogOpen(false);
        }}
        onClose={() => {
          setCreateDialogOpen(false);
        }}
      />
    </React.Fragment>
  );
}

StatementsTable.propTypes = {
  data: PropTypes.array,
  viewer: PropTypes.string,
  username: PropTypes.string,
  nested: PropTypes.bool,
};

export default StatementsTable;
