import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Storage } from 'aws-amplify';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import ControlledInput from 'components/Form/ControlledInput';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { convertMileage } from 'utilities/format';
import { getMileageReportsByAuditStatus } from './queries';
import { updateMileageReport } from 'graphql/mutations';

import { useStyles } from './styles';

export default function MileageReports() {
  const classes = useStyles();
  const [reportsQueue, setReportsQueue] = useState([]);
  const [activeMileageReport, setActiveMileageReport] = useState(null);
  const [activeMileageReportImage, setActiveMileageReportImage] = useState(null);

  // form states
  const { control, errors, handleSubmit, formState, setValue } = useForm();
  const { isSubmitting } = formState;

  const inputs = [{
    type: 'text',
    name: 'odoMileage',
    label: 'Report Odometer Reading',
    InputProps: {
      disabled: true,
    },
  }, {
    type: 'text',
    name: 'actualMileage',
    label: 'Actual Mileage Override',
    autoFocus: true,
    required: true,
    invalidText: 'Actual mileage is required',
  }, {
    type: 'text',
    name: 'reason',
    label: 'Reason (optional)',
    multiline: true,
    rows: 4,
    required: false,
  }];

  useEffect(() => {
    (async () => {
      let reports = await asyncListAll(getMileageReportsByAuditStatus, {
        auditStatus: 'pending',
      });

      if (reports.length > 0) {
        reports = reports.sort((a, b) => a.tsReportDate > b.tsReportDate ? 1 : -1).map((report) => {
          return Object.assign({}, report, {
            odoMileage: convertMileage(report.odoMileage, 'mi'),
          });
        });
        const activeReport = reports.shift();
        setReportsQueue(reports);
        setActiveMileageReport(activeReport);
      }
    })();
  }, []);

  useEffect(() => {
    if (activeMileageReport) {
      (async () => {
        setActiveMileageReportImage(await Storage.get(activeMileageReport.photos[0].storageKey));
        setValue('username', activeMileageReport.username);
        setValue('odoMileage', activeMileageReport.odoMileage.toString());
        setValue('actualMileage', activeMileageReport.odoMileage.toString());
      })();
    }
  }, [activeMileageReport, setValue]);

  if (!activeMileageReport) {
    return (
      <Grid container justify="center" data-test-id="account-review">
        <Grid item xs={12} sm={9} md={6}>
          <Alert severity="info">
            <AlertTitle>There are no pending mileage reports to audit.</AlertTitle>
          </Alert>
        </Grid>
      </Grid>
    );
  }

  async function handleAuditMileageReport({ actualMileage, auditStatus, reason = undefined }) {
    try {
      // update the active mileage report
      await asyncRetryMutation(updateMileageReport, {
        input: {
          auditStatus,
          id: activeMileageReport.id,
          odoMileage: convertMileage(parseInt(actualMileage, 10), 'km'),
          reason,
          tsReportDate: activeMileageReport.tsReportDate,
          username: activeMileageReport.username,
          updatedBy: localStorage.getItem('ruc:username'),
        },
      });

      // update the queue
      if (reportsQueue.length > 0) {
        const activeReport = reportsQueue.shift();
        setReportsQueue(reportsQueue);
        setActiveMileageReport(activeReport);
      } else {
        setActiveMileageReport(null);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  return (
    <Grid container>
      <Grid item xs={1}></Grid>
      <Grid item xs={3} sm={3} lg={2}>
        <Typography component="h1" variant="h5" color="primary">Report Details:</Typography>
        <Divider className={classes.divider} />
        <form
          className={classes.form}
          noValidate
        >
          <Grid container spacing={2}>
            {inputs.map((input, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
            onClick={handleSubmit((fields) => {
              handleAuditMileageReport({
                ...fields,
                auditStatus: 'approved',
              });
            })}
          >
            Approve
          </Button>
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            disabled={isSubmitting}
            onClick={handleSubmit((fields) => {
              handleAuditMileageReport({
                ...fields,
                auditStatus: 'rejected',
              });
            })}
          >
            Reject
          </Button>
        </form>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={7} lg={8}>
        <strong>User</strong>: {activeMileageReport.username}
        <img src={activeMileageReportImage} className={classes.imagePreview} />
        <strong>
          {reportsQueue.length} pending report{(reportsQueue.length === 0 || reportsQueue.length > 1) && (<span>s</span>)} remaining
        </strong>
      </Grid>
    </Grid>
  );
}
