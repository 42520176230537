import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { getParticipant } from 'graphql/queries';
import { updateParticipant } from 'graphql/mutations';
import {
  states,
  regex,
} from 'utilities/constants';
import {
  asyncGet,
  asyncRetryMutation,
} from 'utilities/graph';
import AlertDialog from 'components/AlertDialog';
import ControlledInput from 'components/Form/ControlledInput';
import {
  getCityStateByZip,
  validateAddress,
} from 'utilities/usps';

import { useStyles } from './styles';

const BasicInfo = ({ user }) => {
  const classes = useStyles();

  const [participant, setParticipant] = useState();
  const [formFields, setFormFields] = useState();
  const [error, setError] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const { control, errors, handleSubmit, formState, setValue } = useForm({ mode: 'onChange' });
  const { isSubmitting, isValid, isDirty } = formState;

  const { username } = user;
  const canEdit = participant ? participant.status !== 'closed' : false;

  useEffect(() => {
    (async () => {
      try {
        const [participantRecord] = await Promise.all([
          asyncGet(getParticipant, {
            username,
          }),
        ]);

        setParticipant(participantRecord.data.getParticipant);
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [username]);

  useEffect(() => {
    if (!participant) return;

    setFormFields({
      accountNo: {
        sizes: {},
        props: {
          type: 'text',
          name: 'accountNo',
          defaultValue: participant.accountNo,
          label: 'Account Number',
          required: true,
          inputProps: {
            readOnly: true,
          },
        },
      },
      firstName: {
        sizes: { sm: 6 },
        props: {
          type: 'text',
          name: 'firstName',
          defaultValue: participant.firstName,
          label: 'First Name',
          autoFocus: true,
          required: true,
          invalidText: 'First name is required and must contain only letters, spaces and hyphens',
          pattern: regex.name,
          setValue,
          inputProps: {
            readOnly: !canEdit,
            minLength: 2,
          },
        },
      },
      lastName: {
        sizes: { sm: 6 },
        props: {
          type: 'text',
          name: 'lastName',
          defaultValue: participant.lastName,
          label: 'Last Name',
          required: true,
          invalidText: 'Last name is required and must contain only letters, spaces and hyphens',
          pattern: regex.name,
          setValue,
          inputProps: {
            readOnly: !canEdit,
            minLength: 2,
          },
        },
      },
      email: {
        sizes: {},
        props: {
          type: 'text',
          name: 'email',
          defaultValue: participant.email,
          label: 'Email',
          pattern: regex.email,
          inputProps: {
            readOnly: true,
          },
        },
      },
      phoneNumber: {
        sizes: {},
        props: {
          type: 'text',
          name: 'phoneNumber',
          defaultValue: participant.phoneNumber,
          label: 'Phone Number',
          required: true,
          mask: '(999) 999-9999',
          pattern: regex.phoneNumber,
          invalidText: 'A phone number is required',
          setValue,
          inputProps: {
            readOnly: !canEdit,
          },
        },
      },
      address1: {
        sizes: {},
        props: {
          type: 'text',
          name: 'address1',
          defaultValue: participant.address.address1,
          label: 'Address Line 1',
          required: true,
          invalidText: 'Address is required',
          setValue,
          inputProps: {
            readOnly: !canEdit,
          },
        },
      },
      address2: {
        sizes: {},
        props: {
          type: 'text',
          name: 'address2',
          defaultValue: participant.address.address2,
          label: 'Address Line 2',
          setValue,
          inputProps: {
            readOnly: !canEdit,
          },
        },
      },
      city: {
        sizes: { sm: 6 },
        props: {
          type: 'text',
          name: 'city',
          defaultValue: participant.address.city,
          label: 'City',
          required: true,
          invalidText: 'City is required',
          setValue,
          inputProps: {
            readOnly: !canEdit,
          },
        },
      },
      postalCode: {
        sizes: { sm: 6 },
        props: {
          type: 'number',
          name: 'postalCode',
          defaultValue: participant.address.postalCode.toString(),
          label: 'Zip',
          minLength: 5,
          maxLength: 5,
          required: true,
          invalidText: 'Zip is required',
          inputProps: {
            readOnly: !canEdit,
            onBlur: async (event) => {
              try {
                const result = await getCityStateByZip(event.target.value);
                const { city = '', state = '' } = result;
                setValue('city', city);
                setValue('state', state);
              } catch (e) {
                // swallow invalid zip
              }
            },
          },
        },
      },
      state: {
        sizes: {},
        props: {
          type: 'select',
          name: 'state',
          defaultValue: participant.address.state,
          label: 'State',
          options: Object.keys(states).map((state) => {
            return {
              value: state,
              label: state,
            };
          }),
          required: true,
          invalidText: 'State is required',
          inputProps: {
            readOnly: !canEdit,
          },
        },
      },
    });
  }, [participant, setValue, canEdit]);

  async function handleUpdateParticipant({
    firstName,
    lastName,
    email,
    phoneNumber,
    address1,
    address2,
    city,
    postalCode,
    state,
  }) {
    try {
      const response = await validateAddress({
        address1,
        address2,
        city,
        state,
        postalCode,
      });

      if (
        postalCode !== response.zip ||
        city.toLowerCase() !== response.city.toLowerCase() ||
        state.toLowerCase() !== response.state.toLowerCase()
      ) {
        setError('The provided address is not valid.');
        return;
      }

      if (response.street1.indexOf('PO BOX') !== -1) {
        setError('Post Office Boxes are not allowed.');
        return;
      }
    } catch (e) {
      // api returned errors
      const error = e.toString();
      if (
        error.indexOf('Invalid Zip Code') !== -1 ||
        error.indexOf('Address Not Found') !== -1
      ) {
        setError('The provided address is not valid.');
      }
      return;
    }

    try {
      await asyncRetryMutation(updateParticipant, {
        input: {
          username: participant.username,
          firstName,
          lastName,
          email,
          phoneNumber,
          address: {
            address1,
            address2,
            city,
            state,
            postalCode,
          },
          preferredContactType: participant.address.preferredContactType,
          updatedBy: localStorage.getItem('ruc:username'),
        },
      }, {
        clearCacheKeys: [`${username}.GetParticipant`],
      });

      setOpenAlertDialog(true);
    } catch (e) {
      setError(e.message);
    }
  }

  function handleCloseError() {
    setError(false);
  }

  if (!participant || !formFields) {
    return <div />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountBoxIcon color="inherit" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Update Profile
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleUpdateParticipant)}
        >
          <Grid container spacing={2}>
            {Object.values(formFields).map(({ sizes, props }, index)=>(
              <Grid
                item
                xs={sizes.xs || 12}
                sm={sizes.sm || 12}
                key={index}>
                <ControlledInput
                  control={control}
                  errors={errors}
                  {...props}
                />
              </Grid>
            ))}
          </Grid>
          {!canEdit && (
            <Box marginTop={2}>
              <Alert severity="info">Changes to your profile are disabled until you have been fully onboarded.</Alert>
            </Box>
          )}
          {canEdit && <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!canEdit || !isDirty || !isValid || isSubmitting}
          >
            Update
          </Button>}
        </form>
        <Snackbar
          open={error !== false}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
        <AlertDialog
          open={openAlertDialog}
          onClose={() => setOpenAlertDialog(false)}
          title="Success!"
          text="Your account address / phone has been updated."
        />
      </div>
    </Container>
  );
};

BasicInfo.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    attributes: PropTypes.shape({
      email: PropTypes.string,
      given_name: PropTypes.string,
      family_name: PropTypes.string,
    }),
  }),
};

export default BasicInfo;
