import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';

import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import {
  getParticipantStatementsByParticipantByMonth,
} from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';

import { useStyles } from './styles';

function Statements({ username }) {
  const classes = useStyles();
  const [statements, setStatements] = useState([]);

  const download = async (filename) => {
    const key = `participant/${username}/statements/${filename}`;
    const url = await Storage.get(key);
    console.log('Statement URLs:', url);
    window.open(url);
  };

  useEffect(() => {
    (async () => {
      try {
        const records = await asyncListAll(getParticipantStatementsByParticipantByMonth, {
          username,
          filter: {
            shouldSendEmailToParticipant: {
              eq: true,
            },
          },
        }, { bypassCache: true });
        setStatements(records.sort(sortBy('month', true)));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [username]);

  return (
    <Container component="main">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <ReceiptOutlinedIcon color="inherit" />
        </Avatar>
        <div className={classes.paperFull}>
          <Typography component="h1" variant="h5">
            Statements
          </Typography>
          {statements.length > 0 ? (
            <div className={classes.list}>
              <List>
                {statements.map(({ month, createdBy, periodFrom, periodTo, filename, balance }, index) => (
                  <ListItem key={index} divider>
                    <ListItemText
                      primary={
                        createdBy === 'System' ? ` ${moment(periodFrom).format('MMMM DD, YYYY')} Monthly Statement` :
                          `${moment(periodFrom).format('YYYY/MM/DD')} - ${moment(periodTo).format('YYYY/MM/DD')} Statement`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => download(filename)}>
                        <GetAppIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          ) : (
            <Alert color="info">
              <p>You do not have any statements.  Statements will be generated and emailed to you on a monthly basis.</p>
            </Alert>
          )}
        </div>
      </div>
    </Container>
  );
}

Statements.propTypes = {
  username: PropTypes.string,
};


export default Statements;
