const NP = require('number-precision');
const moment = require('moment');

NP.enableBoundaryChecking(false);

const miToKmRatio = 0.62137119223733387264;
const mpgToKplRatio = 0.425143707;

export const formatAddress = (inAddressObject) => {
  if (!inAddressObject) return '';
  const {
    address1,
    address2,
    city,
    state,
    postalCode,
    extendedPostalCode,
  } = inAddressObject;

  return [
    `${address1 ? address1 : ''}`,
    `${address2 ? ` ${address2}` : ''}`,
    `${city ? `, ${city}` : ''}`,
    `${state ? `, ${state}` : ''}`,
    `${postalCode ? ` ${postalCode}` : ''}`,
    `${extendedPostalCode ? `-${extendedPostalCode}` : ''}`,
  ].join('');
};

export const formatFuel = (value = 0, inUnit) => {
  const unit = inUnit || localStorage.getItem('ruc:configuration:FUEL_UNIT') || 'l';

  switch ((unit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return convertFuel(value, 'gal');
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return value;
  }
};

export const formatFuelUnit = (value, unit = 'l') => {
  switch ((unit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      // this is intent to use "l" for calculating "cents per gallons" (fuel unit in denominator)
      return convertFuel(value, 'l');
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return value;
  }
};

export const convertFuel = (value, targetUnit) => {
  switch ((targetUnit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return parseFloat((value * 0.264172).toFixed(2));
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return parseFloat((value * 3.78541).toFixed(2));
  }
};

export const formatFuelRate = (value, unit = 'l') => {
  switch ((unit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return convertFuelRate(value, 'gal');
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return value;
  }
};

export const convertFuelRate = (value, targetUnit) => {
  const galToLRatio = 0.26417205235814842368;

  switch ((targetUnit).toLowerCase()) {
    case 'gal':
    case 'gals':
    case 'gallon':
    case 'gallons':
      return NP.round(NP.times(value, (1 / galToLRatio)), 2);
    case 'l':
    case 'ls':
    case 'liter':
    case 'liters':
    default:
      return NP.round(NP.times(value, galToLRatio), 2);
  }
};

export const formatMileage = (inValue = 0, inUnit, withUnit = false) => {
  const unit = inUnit || localStorage.getItem('ruc:configuration:MILEAGE_UNIT') || 'km';
  let value;

  switch ((unit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      value = convertMileage(inValue, 'mi');
      break;
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      value = inValue;
      break;
  }

  return `${value}${withUnit ? ` ${unit}` : ''}`;
};

export const formatMileageUnit = (inValue, inUnit, withUnit = false) => {
  const unit = inUnit || localStorage.getItem('ruc:configuration:MILEAGE_UNIT') || 'km';
  let value;

  switch ((unit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      // this is intent to use "km" for calculating "cents per miles" (mileage unit in denominator)
      value = convertMileage(inValue, 'km');
      break;
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      value = inValue;
      break;
  }

  return `${value}${withUnit ? ` ${unit}` : ''}`;
};


export const convertMileage = (value, targetUnit = 'km') => {
  switch ((targetUnit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      return NP.round(NP.times(value, miToKmRatio), 2);
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      return NP.round(NP.times(value, (1 / miToKmRatio)), 2);
  }
};

export const convertMileageRate = (value, targetUnit = 'km') => {
  switch ((targetUnit).toLowerCase()) {
    case 'mi':
    case 'mis':
    case 'mile':
    case 'miles':
      return NP.round(NP.times(value, (1 / mpgToKplRatio)), 2);
    case 'km':
    case 'mks':
    case 'kilometer':
    case 'kilometers':
    default:
      return NP.round(NP.times(value, mpgToKplRatio), 2);
  }
};

export const simpleDate = (value) => {
  return moment(value).format('MM-DD-YYYY');
};

export const formatCurrency = (value = 0) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value / 100);
};
