import React, { useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import { asyncListAll } from 'utilities/graph';
import {
  getEventsByUserByTimestamp,
  listParticipantsWithVehicles,
} from './graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function mapFields(participant, vehicle, vehicleDeleted) {
  const id = `${participant.accountNo}-${vehicle.vin}`;

  const odo = vehicle.reports == undefined ? [] : vehicle.reports.sort((a, b) => {
    return moment(a.tsReportDate).isBefore(b.tsReportDate) ? -1 : 1;
  });

  return {
    'RecordID': id,
    'AccountID': participant.accountNo,
    'Last Name': participant.lastName,
    'First Name': participant.firstName,
    'Email Address': participant.email,
    'Telephone Number': participant.phoneNumber,
    'Employer': participant.governmentAffiliation || '',
    'RUC Group': participant.pilotProgram == undefined ? '' : participant.pilotProgram.shortName === 'MBUF+DR' ? 'Variable Rate' : 'Flat Rate',
    'VIP': participant.flags == undefined ? false : participant.flags.isVIP || false,
    'VIN': vehicle.vin,
    'Starting Odometer': odo.length > 0 ? odo[0].odometer : '',
    'Starting Odometer Date': odo.length > 0 ? odo[0].tsReportDate : '',
    'Final Odometer': odo.length > 0 ? odo.slice(-1)[0].odometer : '',
    'Final Odometer Date': odo.length > 0 ? odo.slice(-1)[0].tsReportDate : '',
    'MRO ID': vehicle.mro ? vehicle.mro.deviceSerialNumber : '',
    'MRO Type': vehicle.mro ? vehicle.mro.mroDevicePreference : '',
    'VIN Delete Date': vehicleDeleted == undefined ? '' : vehicleDeleted.createdAt,
    'VIN Delete Reason': vehicleDeleted == undefined ? '' : 'Removed by CSR',
    'Account Close Date': participant.closedDate == undefined ? '' : participant.closedDate,
    'Account Close Reason': participant.closedReason == undefined ? '' : participant.closedReason,
    'MRO (PID) Return Date': 'Refer to CSR',
  };
}

export default function PilotCloseoutReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      const eventTo = moment.utc().format();
      const participants = await asyncListAll(listParticipantsWithVehicles, { filter: { onboardedDate: { between: [from, to] } } });
      const data = await Promise.all(participants.map(async (participant) => {
        const events = await asyncListAll(getEventsByUserByTimestamp, { username: participant.username, timestamp:
          { between: [participant.createdAt, eventTo] }, filter: { eventName: { eq: 'REMOVE' } } });
        const filteredVehicleEvents = events.filter(({ key, eventName }) => key.match(/^Vehicle/));

        return participant.vehicles.items.map((vehicle) => {
          const vehicleDeleted = filteredVehicleEvents.find(({ diff }) => {
            return diff.find(({ key, old }) => key === 'id' && old === `"${vehicle.id}"`);
          });
          return mapFields(participant, vehicle, vehicleDeleted);
        });
      }));

      return data.flat();
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Pilot Closeout Report
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => {
                e.target.value = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
                setFrom(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => {
                e.target.value = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
                setTo(e.target.value);
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Pilot_Closeout_Report.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
