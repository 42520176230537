import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PaymentIcon from '@material-ui/icons/Payment';
import StarIcon from '@material-ui/icons/Star';
import Typography from '@material-ui/core/Typography';

import { listPaymentCards } from 'graphql/queries';
import { asyncListAll } from 'utilities/graph';

const SelectPaymentMethod = ({
  username,
  classes,
  onPaymentSelected,
  setError,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    const refreshPaymentMethods = async (username) => {
      try {
        const paymentCards = await asyncListAll(listPaymentCards, { username });
        setPaymentMethods(paymentCards || []);
      } catch (e) {
        console.warn(e);
        setError(e.message);
      }
    };

    (async () => {
      await refreshPaymentMethods(username);
    })();
  }, [username, setError]);

  const handleSelectItem = (method) => {
    setDisabled(true);
    onPaymentSelected(method);
  };

  const existing = () => {
    if (paymentMethods.length === 0) {
      return <></>;
    }

    return (
      <div>
        <Typography component="h1" variant="h5">Use existing payment methods</Typography>
        {paymentMethods.length === 0 ? (
          <List>
            <ListItem>
              <ListItemText
                primary="No registered payment methods"
              />
            </ListItem>
          </List>
        ) : (
          <List>
            {paymentMethods.map((method) => {
              return (
                <ListItem
                  key={`payment-method-${method.id}`}
                  button
                  onClick={() => {
                    if (!disabled) {
                      handleSelectItem(method);
                    }
                  }}>
                  {!method.isDefault ? null : (
                    <ListItemIcon>
                      <StarIcon color="primary" />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primary={method.alias ? method.alias : `**** **** **** ${method.last4.toString().padStart(4, '0')}`}
                    secondary={`Expires on ${method.expirationMonth}/${method.expirationYear}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                    >
                      <PaymentIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        )}
      </div>
    );
  };

  return (
    <div className={classes.paper}>
      <hr className={classes.divider} />
      {existing()}
    </div>
  );
};

SelectPaymentMethod.propTypes = {
  username: PropTypes.string,
  classes: PropTypes.object,
  onPaymentSelected: PropTypes.func,
  setError: PropTypes.func,
};

export default SelectPaymentMethod;
