import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function mapFields() {
  return {
    'Total Number of Inbound eMails': '',
    'Number of Inbound eMails (After Business Hrs)': '',
    'Number of Inbound eMails - VIPs': '',
    'Total Number of Inbound Calls': '',
    'Number of Inbound Calls (During Business Hrs)': '',
    'Number of Inbound Calls (After Business Hrs)': '',
    'Number of Inbound Calls - VIPs': '',
    'Total Number of Inbound Contact via UAP': '',
    'Number of Inbound Contact via UAP (During Business Hrs)': '',
    'Number of Inbound Contact via UAP (After Business Hrs)': '',
    'Number of Inbound Contact via UAP - VIPs': '',
    'Total Number of Outbound Calls': '',
    'Number of Outbound Calls (During Business Hrs)': '',
    'Number of Outbound Calls - VIPs': '',
  };
}

export default function CustomerInteractionMetricsReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const query = async () => {
    try {
      setIsSubmitting(true);
      return mapFields();
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Customer Interaction Metrics Report
            </Typography>
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Customer_Interactions_Metrics_Template.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={async () => {
                const data = await query();
                // const options = data.length > 0 ? {} : { fields: ['no-inquiries-in-range'] };
                const parser = new Parser();
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Report Template
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
