export const getTransactionsByTypeByCreatedAt = /* GraphQL */ `
  query GetTransactionsByTypeByCreatedAt(
    $type: TransactionType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByTypeByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        description
        type
        status
        retries
        subtotalCents
        discountCents
        amountCents
        paymentMethodId
        participant {
          firstName
          lastName
          email
          phoneNumber
        }
        paidAt
      }
      nextToken
    }
  }
`;

export const listParticipantsWithVehicles = /* GraphQL */ `
  query ListParticipants(
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        accountNo
        firstName
        lastName
        ageGroup
        gender
        email
        firstDataReceivedDate
        preferredContactType
        governmentAffiliation
        mroDevicePreference
        closedDate
        closedReason
        address {
          address1
          address2
          city
          state
          postalCode
          extendedPostalCode
        }
        onboardedDate
        createdAt
        pilotProgram {
          shortName
        }
        flags {
          hasIntegrityViolation
          isBillingOverdue
          isBillingDefault
          isInactive
          isLegislator
          isVinMismatch
          isVIP
          isGovernmentEmployee
          isCaliforniaElected
          agreeGlobalParticipantAgreement
          agreeGlobalPrivacyPolicy
        }
        phoneNumber
        vehicles {
          items {
            id
            vin
            make
            model
            year
            type
            licensePlate
            registrationState
            reports {
              odometer
              tsReportDate
            }
            mroType
            epaVehicleCombinedMpg
            firstDataReceivedDate
            mro {
              id
              deviceSerialNumber
              shippedDate
              shippingCarrier
              deliveredDate
              activationDate
              firstReportedDate
              createdAt
              updatedAt
            }
            createdAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listParticipants = /* GraphQL */ `
  query ListParticipants(
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        accountNo
        firstName
        lastName
        ageGroup
        gender
        email
        firstDataReceivedDate
        preferredContactType
        governmentAffiliation
        mroDevicePreference
        closedDate
        closedReason
        address {
          address1
          address2
          city
          state
          postalCode
          extendedPostalCode
        }
        onboardedDate
        createdAt
        pilotProgram {
          shortName
        }
        flags {
          hasIntegrityViolation
          isBillingOverdue
          isBillingDefault
          isInactive
          isLegislator
          isVinMismatch
          isVIP
          isGovernmentEmployee
          isCaliforniaElected
          agreeGlobalParticipantAgreement
          agreeGlobalPrivacyPolicy
        }
        phoneNumber
      }
      nextToken
    }
  }
`;

export const getParticipantWithVehicles = /* GraphQL */ `
  query GetParticipant($username: String!) {
    getParticipant(username: $username) {
      username
      accountNo
      firstName
      lastName
      ageGroup
      gender
      email
      firstDataReceivedDate
      preferredContactType
      governmentAffiliation
      mroDevicePreference
      address {
        address1
        address2
        city
        state
        postalCode
        extendedPostalCode
      }
      onboardedDate
      createdAt
      pilotProgram {
        shortName
      }
      flags {
        hasIntegrityViolation
        isBillingOverdue
        isBillingDefault
        isInactive
        isLegislator
        isVinMismatch
        isVIP
        isGovernmentEmployee
        isCaliforniaElected
        agreeGlobalParticipantAgreement
        agreeGlobalPrivacyPolicy
      }
      phoneNumber
      vehicles {
        items {
          id
          vin
          make
          model
          year
          type
          licensePlate
          registrationState
          reports {
            odometer
            tsReportDate
          }
          mroType
          epaVehicleCombinedMpg
          firstDataReceivedDate
          mro {
            id
            deviceSerialNumber
            shippedDate
            shippingCarrier
            deliveredDate
            activationDate
            firstReportedDate
            createdAt
            updatedAt
          }
          createdAt
        }
        nextToken
      }
    }
  }
`;

export const listParticipantInquirysWithCommentsParticpantsVehicles = /* GraphQL */ `
  query ListParticipantInquirys(
    $filter: ModelParticipantInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipantInquirys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        assignee
        category
        subject
        body
        status
        name
        email
        emailOriginalMessageId
        bodyHtml
        participant {
          username
          accountNo
          firstName
          lastName
          email
          preferredContactType
          governmentAffiliation
          address {
            address1
            address2
            city
            state
            postalCode
            extendedPostalCode
          }
          onboardedDate
          pilotProgram {
            shortName
          }
          flags {
            hasIntegrityViolation
            isBillingOverdue
            isBillingDefault
            isInactive
            isLegislator
            isVinMismatch
            isVIP
            isGovernmentEmployee
            isCaliforniaElected
            agreeGlobalParticipantAgreement
            agreeGlobalPrivacyPolicy
          }
          createdAt
          phoneNumber
          vehicles {
            items {
              id
              vin
              make
              model
              year
              type
              licensePlate
              registrationState
              mroType
              createdAt
              mro {
                id
                deviceSerialNumber
                shippedDate
                shippingCarrier
                deliveredDate
                activationDate
                firstReportedDate
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
        comments {
          items {
            id
            username
            author
            body
            createdAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getEventsByUserByTimestamp = /* GraphQL */ `
  query GetEventsByUserByTimestamp(
    $username: String
    $timestamp: ModelStringKeyConditionInput
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getEventsByUserByTimestamp(
      username: $username
      timestamp: $timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        timestamp
        username
        updatedBy
        eventId
        eventName
        diff {
          key
          old
          new
        }
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getEventsByEventNameByTimestamp = /* GraphQL */ `
  query GetEventsByEventNameByTimestamp(
    $eventName: String
    $timestamp: ModelStringKeyConditionInput
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getEventsByEventNameByTimestamp(
      eventName: $eventName
      timestamp: $timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        timestamp
        username
        updatedBy
        eventId
        eventName
        diff {
          key
          old
          new
        }
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTripsWithVehicles = /* GraphQL */ `
  query ListTrips(
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        recordId
        recordTripId
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        createdAt
        processStatus
        processMsg
        processedAt
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        updatedAt
        vehicle {
          id
          vin
          make
          model
          year
          type
          licensePlate
          registrationState
          mroType
          createdAt
          mro {
            id
            deviceSerialNumber
            shippedDate
            shippingCarrier
            deliveredDate
            activationDate
            firstReportedDate
            createdAt
            updatedAt
          }
        }
        adjustments {
          items {
            tripId
            id
            type
            state
            stateCode
            cordonId
            cordonName
            todId
            todName
            tollId
            adjMileage
            adjMileageFeeCents
            adjFuel
            adjFuelFeeCents
            adjCordonFeeCents
            adjTodFeeCents
            adjTollFeeCents
            note
            content
            createdBy
            createdAt
            transactionId
            paymentStatus
            updatedAt
          }
          nextToken
        }
        tripSegments {
          items {
            mileage
            type
            stateCode
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listTripsWithTransactions = /* GraphQL */ `
  query ListTrips(
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        recordId
        recordTripId
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        createdAt
        processStatus
        processMsg
        processedAt
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        updatedAt
        vehicle {
          id
          vin
          make
          model
          year
          type
          licensePlate
          registrationState
          mroType
          createdAt
          mro {
            id
            deviceSerialNumber
            shippedDate
            shippingCarrier
            deliveredDate
            activationDate
            firstReportedDate
            createdAt
            updatedAt
          }
        }
        tripSegments {
          items {
            participant {
              username
              accountNo
              firstName
              lastName
              email
            }
            transaction {
              id
              updatedAt
              createdAt
              status
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listTripAdjustments = /* GraphQL */ `
  query ListTripAdjustments(
    $filter: ModelTripAdjustmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTripAdjustments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tripId
        id
        username
        vehicleId
        tripSegmentId
        tripSegmentDate
        type
        state
        stateCode
        cordonId
        cordonName
        todId
        todName
        tollId
        adjMileage
        adjMileageFeeCents
        adjFuel
        adjFuelFeeCents
        adjCordonFeeCents
        adjTodFeeCents
        adjTollFeeCents
        note
        content
        createdBy
        createdAt
        transactionId
        paymentStatus
        updatedAt
      }
      nextToken
    }
  }
`;

export const getParticipantStatementsByUsernameByCreatedAt = /* GraphQL */ `
  query GetParticipantStatementByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelParticipantStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getParticipantStatementByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        month
        periodFrom
        periodTo
        filename
        mileage
        fuel
        trips
        mbufFee
        cordonFee
        todFee
        tollFee
        fuelTaxCredit
        balance
        paidAmount
        paidAt
        note
        isProcessed
        shouldSendEmailToParticipant
        tripsSummary {
          vehicleId
          privateFuelFee
          privateFuels
          privateMileage
          privateMileageFee
          privateNet
          privateTaxDifference
          publicFuelFee
          publicFuels
          publicMileage
          publicMileageFee
          publicTaxDifference
          stateCode
        }
        adjustmentSummary {
          vehicleId
          adjMileage
          adjMileageFee
          adjFuel
          adjFuelFee
          shortDate
        }
        vehiclesSummary {
          vehicleId
          fuel
          fuelFee
          mileage
          mileageFee
          taxDifference
        }
        createdAt
        createdBy
        updatedAt
      }
      nextToken
    }
  }
`;

export const listParticipantStatements = /* GraphQL */ `
  query ListParticipantStatements(
    $filter: ModelParticipantStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipantStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        month
        periodFrom
        periodTo
        filename
        mileage
        fuel
        trips
        mbufFee
        cordonFee
        todFee
        tollFee
        fuelTaxCredit
        balance
        paidAmount
        paidAt
        note
        isProcessed
        shouldSendEmailToParticipant
        tripsSummary {
          vehicleId
          privateFuelFee
          privateFuels
          privateMileage
          privateMileageFee
          privateNet
          privateTaxDifference
          publicFuelFee
          publicFuels
          publicMileage
          publicMileageFee
          publicTaxDifference
          stateCode
        }
        adjustmentSummary {
          vehicleId
          adjMileage
          adjMileageFee
          adjFuel
          adjFuelFee
          shortDate
        }
        vehiclesSummary {
          vehicleId
          fuel
          fuelFee
          mileage
          mileageFee
          taxDifference
        }
        createdAt
        createdBy
        updatedAt
      }
      nextToken
    }
  }
`;

export const listMROEventsWithParticipants = /* GraphQL */ `
  query ListMROEvents(
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMROEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        code
        odometerReadingStart
        odometerReading
        mileage
        type
        description
        dateTime
        source
        processLogs
        flagged
        isProcessed
        id
        mroDeviceSerialNumber
        username
        vehicleId
        tripId
        createdAt
        updatedAt
        participant {
          username
          accountNo
          firstName
          lastName
          email
          preferredContactType
          governmentAffiliation
          closedDate
          closedReason
          pilotProgram {
            shortName
          }
          flags {
            hasIntegrityViolation
            isBillingOverdue
            isBillingDefault
            isInactive
            isLegislator
            isVinMismatch
            isVIP
            isGovernmentEmployee
            isCaliforniaElected
            agreeGlobalParticipantAgreement
            agreeGlobalPrivacyPolicy
          }
          createdAt
          phoneNumber
          vehicles {
            items {
              id
              vin
              mroType
              createdAt
              mro {
                id
                deviceSerialNumber
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
