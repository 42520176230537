import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PowerIcon from '@material-ui/icons/Power';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { listParticipantConnections } from './queries';
import { listVehicles } from 'graphql/queries';
import {
  createParticipantConnection,
  updateParticipantConnection,
  updateVehicle,
} from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { connect, rucScope } from 'utilities/smartcar';

import { useStyles } from './styles';

const Connections = ({ user }) => {
  const classes = useStyles();

  const [connections, setConnections] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [refreshConnections, setRefreshConnections] = useState([]);

  const { username } = user;

  useEffect(() => {
    (async () => {
      try {
        const [
          connections,
          vehicles,
        ] = await Promise.all([
          asyncListAll(listParticipantConnections, { username }),
          asyncListAll(listVehicles, { username }),
        ]);

        setConnections(connections);
        setVehicles(vehicles);
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [username, refreshConnections]);

  const connectSmartcar = async ({
    vehicle: inVehicle = null,
    connection: inConnection = null,
  }) => {
    const vehicle = !inVehicle ?
      vehicles.find(({ id }) => id === inConnection.vehicleId) : Object.assign({}, inVehicle);
    try {
      const code = await connect(rucScope, {
        vehicleInfo: {
          make: vehicle.make.toUpperCase(),
        },
      });

      console.log('code returned', code);

      let input = {
        username,
        authorizationCode: code,
        resourceProvider: 'smartcar',
        vehicleId: vehicle.id,
      };

      if (inConnection) {
        input = Object.assign({}, inConnection, input, {
          accessToken: '',
          forceRestoreConnection: false,
          refreshToken: '',
          updatedAt: new Date().toISOString(),
        });
        await asyncRetryMutation(updateParticipantConnection, {
          input,
        });
      } else {
        const response = await asyncRetryMutation(createParticipantConnection, {
          input,
        });

        // update vehicle
        const {
          data: {
            createParticipantConnection: {
              id: participantConnectionId,
            },
          },
        } = response;

        delete vehicle.createdAt;
        delete vehicle.updatedAt;
        await asyncRetryMutation(updateVehicle, {
          input: Object.assign({}, vehicle, {
            participantConnectionId,
          }),
        });
      }

      setRefreshConnections(!refreshConnections);
    } catch (e) {
      console.warn(e.message);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      {vehicles.filter(({ mroType, participantConnectionId }) => {
        return mroType === 'telematics' && participantConnectionId === null;
      }).length > 0 && (
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" className={classes.connectionHeading}>
              Pending Connections
          </Typography>
          <div className={classes.connectionList}>
            <List>
              {
                vehicles.filter(({ mroType, participantConnectionId }) => {
                  return mroType === 'telematics' && participantConnectionId === null;
                }).map((vehicle, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <PowerIcon />
                      </ListItemIcon>
                      <ListItemText primary={`${vehicle.make} ${vehicle.model}`} />
                      <ListItemSecondaryAction>
                        <Button
                          size="large"
                          fullWidth
                          variant="contained"
                          color="primary"
                          key={index}
                          onClick={() => {
                            connectSmartcar({ vehicle });
                          }}
                        >Connect</Button>
                      </ListItemSecondaryAction>
                      <Divider />
                    </ListItem>
                  );
                })
              }
            </List>
          </div>
        </div>
      )}
      {(vehicles.length > 0 && connections.length > 0) && (
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" className={classes.connectionHeading}>
            Active Connections
          </Typography>
          <div className={classes.connectionList}>
            <List>
              {connections.map((connection, index) => {
                let vehicle = vehicles.find(({ participantConnectionId }) => participantConnectionId === connection.id);
                if (!vehicle) {
                  vehicle = vehicles[0];
                }
                if (connection.resourceProvider === 'smartcar') {
                  return (
                    <ListItem key={index}>
                      <ListItemIcon>
                        {connection.forceRestoreConnection ? (
                          <Tooltip title="Restore Connection" placement="right">
                            <ClearIcon color="error" />
                          </Tooltip>
                        ) : (
                          <CheckIcon color="success" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={`Smartcar - ${vehicle.make} ${vehicle.model}`} />
                      <ListItemSecondaryAction>
                        {connection.forceRestoreConnection && (
                          <Button
                            size="large"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            key={index}
                            onClick={() => {
                              connectSmartcar({ connection });
                            }}
                          >Reconnect</Button>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }
              })}
            </List>
          </div>
        </div>
      )}

    </Container>
  );
};

Connections.propTypes = {
  user: PropTypes.object,
};

export default Connections;
