import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';
import heic2any from 'heic2any';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

const cache = {};

export default function ImagePreviewDialog({
  imgS3Key,
  onClose,
}) {
  const [isLoading, setIsLoading] = useState();
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    if (!imgS3Key) {
      setImgUrl(null);
      return;
    }

    if (cache[imgS3Key]) {
      setImgUrl(cache[imgS3Key]);
      return;
    }

    (async () => {
      setIsLoading(true);

      const result = await Storage.get(
        imgS3Key, { level: 'public' },
      );

      if (imgS3Key.endsWith('.heic')) {
        try {
          const res = await fetch(result);
          const blob = await res.blob();
          const conversionResult = await heic2any({ blob });
          const url = URL.createObjectURL(conversionResult);

          cache[imgS3Key] = url;
          setImgUrl(url);
        } catch (e) {
          console.log(e);
        }
      } else {
        cache[imgS3Key] = result;
        setImgUrl(result);
      }

      setIsLoading(false);
    })();
  }, [imgS3Key]);

  if (!imgS3Key) return null;

  return (<Dialog
    open={true}
    onClose={onClose}
    maxWidth={'xl'}
  >
    <Toolbar>
      <IconButton edge="start" color="inherit" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Toolbar>
    <DialogContent>
      {isLoading ? <CircularProgress color="inherit" /> : <img width="100%" src={imgUrl} />}
    </DialogContent>
  </Dialog>);
}

ImagePreviewDialog.propTypes = {
  imgS3Key: PropTypes.string,
  onClose: PropTypes.func,
};
